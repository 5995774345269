import React, {useEffect, useState} from 'react'
import AddNewBtn from './AddNewBtn'
// import './style.css'
import {Link} from 'react-router-dom'
import {getCount} from '../../../../app/API/GridAPI'
import {Message} from '../../../helpers'
import { useAuth } from '../../../../app/pages/auth'

export const DbCard1 = () => {
  const [count, setcount] = useState<any>()
  const {currentUser, logout} = useAuth()
  useEffect(() => {
    let mounted = true
    if (mounted) {
      getCount().then((res) => {
        if (res.statusCode == 200) {
          setcount(res.data)
        } else {
          Message(res.message, 'error')
          setcount(res.data)
        }
      })
    }
    return () => {
      mounted = false
    }
  }, [])

  return (
    <div>
      <div className='container'>
        <div className='row equal-cols'>
          {
currentUser?.userRoles[0]?.roles === 'account'?'':
<div className='col-3 '>
<div className='p-3 border border-dark clr1  rounded'>
  <Link to='/Client'>
    <div className='d-flex justify-content-between'>
      <h5>CLIENTS</h5>
      <img alt='clientLogo' src='/media/svg/dashboard/group1.svg' />
    </div>
    <h4>{count?.clientCount ?? '0'}</h4>
  <div className='d-flex justify-content-between align-items-center'>
    {/* <AddNewBtn /> */}
    {/* <i className='fa-solid fa-circle-arrow-right text-primary fs-1'></i> */}
  </div>
  </Link>
</div>
</div>
        }
  

          <div className='col-3'>
            <Link to={`${currentUser?.userRoles[0]?.roles === 'admin'?'/admin/dashboard':currentUser?.userRoles[0]?.roles === 'account'?'/account/dashboard':''}`}>
              <div className='p-3 border border-dark clr1  bg-light-info rounded'>
                <div className='d-flex justify-content-between'>
                  <h5>BILLING & PAYMENT</h5>
                  <img alt='bilingLogo' src='/media/svg/dashboard/group2.svg' />
                </div>
                <h4>
                  Due:{count?.dueCount ?? '0'}
                  <br />
                  Over Due:{count?.overdueCount ?? '0'}
                </h4>
                <br />
              </div>
            </Link>
          </div>

          <div className='col-3'>
            <div className='p-3 border border-dark clr1 rounded'>
              <Link to='/ServicesProvider'>
                <div className='d-flex justify-content-between'>
                  <h5>SERVICES PROVIDER</h5>
                  <img alt='serviceLogo' src='/media/svg/dashboard/group3.svg' />
                </div>
                <h4>{count?.venderCount ?? '0'}</h4>
              <div className='d-flex justify-content-between align-items-center'>
                {/* <AddNewBtn /> */}
                {/* <i className='fa-solid fa-circle-arrow-right text-primary fs-1'></i> */}
              </div>
              </Link>
            </div>
          </div>

          <div className='col-3 '>
            <Link to='/BillingHistory'>
              <div className='p-3 border border-dark clr1 rounded'>
                <div className='d-flex justify-content-between'>
                  <h5>HISTORY</h5>
                  <img alt='historyLogo' src='/media/svg/dashboard/group4.svg' />
                </div>
                <h4 className='pb-5'>{count?.transactionCount ?? '0'}</h4>
                <br />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}
