/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import {checkRole, KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {useAuth} from '../../../../app/pages/auth'
import roleContext from '../../../../context/roleContext'
import {useContext} from 'react'
import Calender from '../../../partials/widgets/charts/Calender'

export function AsideMenuMain() {
  const intl = useIntl()
  let roleState: any = useContext(roleContext)
  // console.log(roleState)
  const {currentUser, logout} = useAuth()

  return (
    <>
      {/* <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen025.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
      />

      <AsideMenuItem to='/Client' title='Clients' />
      <AsideMenuItem to='/ServicesProvider' title='Services Provider' />
      <AsideMenuItem to='/BillingHistory' title='Billing History' />
      <AsideMenuItem to='/Notification' title='Notifications' />
      <AsideMenuItem to='/NewUser' title='Users' />
      <AsideMenuItem to='/Support' title='Support' /> */}

      {currentUser?.userRoles[0]?.roles === 'admin' ? (
        <>
          <AsideMenuItem title={intl.formatMessage({id: 'MENU.DASHBOARD'})} to='/admin/dashboard' />
          {checkRole(['client'], roleState.permissions) ? (
            <AsideMenuItem to='/Client' title='Client' />
          ) : (
            ''
          )}
          {checkRole(['service_provider'], roleState.permissions) ? (
            <AsideMenuItem to='/ServicesProvider' title='Services Provider' />
          ) : (
            ''
          )}

          {checkRole(['billing_history'], roleState.permissions) ? (
            <AsideMenuItem to='/BillingHistory' title='Billing History' />
          ) : (
            ''
          )}

          {checkRole(['notification'], roleState.permissions) ? (
            <AsideMenuItem to='/Notification' title='Notifications' />
          ) : (
            ''
          )}
          {checkRole(['support'], roleState.permissions) ? (
            <AsideMenuItem to='/Support' title='Support' />
          ) : (
            ''
          )}
          {checkRole(['user'], roleState.permissions) ? (
            <AsideMenuItem to='/NewUser' title='Users' />
          ) : (
            ''
          )}
        </>
      ) : currentUser?.userRoles[0]?.roles === 'account' ? (
        <>
          <AsideMenuItem
            title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
            to='/account/dashboard'
          />
          {checkRole(['service_provider'], roleState.permissions) ? (
            <AsideMenuItem to='/ServicesProvider' title='Services Provider' />
          ) : (
            ''
          )}
          {checkRole(['billing_history'], roleState.permissions) ? (
            <AsideMenuItem to='/BillingHistory' title='Billing History' />
          ) : (
            ''
          )}
        </>
      ) : currentUser?.userRoles[0]?.roles === 'support' ? (
        <>
          {checkRole(['support'], roleState.permissions) ? (
            <AsideMenuItem to='/support/dashboard' title='Support' />
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}

      {/* currentUser?.userRoles[0]?.roles === 'account' ? (
        <>
          {checkRole([''service_provider''], roleState.permissions) ? (
            <AsideMenuItem to='/Support' title='Support' />
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )
      }  */}
      <br />
      <br />
      <div className='menu-item '>
        <Calender />
      </div>
    </>
  )
}
