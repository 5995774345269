import SearchBar from './SearchBtn'
import './style.css'
import AddNewBtn from './AddNewBtn'
// import './style.css'
import {Link} from 'react-router-dom'
import React, {useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.css'

import {toast} from 'react-toastify'
import {
  addReceiptTransactions,
  addTransactions,
  getAllTransactions,
} from '../../../../app/API/TransactionAPI'
import {getAllNextweek, getAllOverDue, getAllThisweek, getDueOverDueVendor} from '../../../../app/API/GridAPI'
import {calculateDateDifference, Message} from '../../../helpers'
import InputError from '../../../layout/components/InputError'
import {getAllVendors} from '../../../../app/API/ServicesAPI'

const $ = require('jquery')
export const DbCard2 = () => {
  const initialValues = {
    TransactionId: '',
    TransactionDate: '',
    Invoice: '',
    Receipt: '',
    Comment: '',
    serviceProvider: '',
    duePayments: '',
    overDuePayment: '',
    isDisableSp: 'false',
    isDisableDuePayments: 'false',
    isDisableOverDuePayments: 'false',
  }
  const [vendorDetail, setVendorDetail] = useState({id: '', amount: ''})
  const [vendor, setVendor] = useState([])
  const [overdue, setOverdue] = useState([])
  const [filterVendor, setFilterVendor] = useState([])
  const [filterOverdue, setFilterOverdue] = useState([])
  const [filterThisWeek, setFilterThisWeek] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [formErrors, setFormErrors] = useState<any>({})
  const [thisweek, setThisweek] = useState([])
  const [formValues, setFormValues] = useState(initialValues)


  const getOverdue = () => {
    getAllOverDue().then((res) => {
      if (res.statusCode == 200) {
        setOverdue(res.data)
        setFilterOverdue(res.data)
      } else {
        setOverdue([])
        setFilterOverdue([])
        Message(res.message, 'error')
      }
    })
  }

  const handleCancel = () => {
    setFormValues({
      ...formValues,
      TransactionId: '',
      TransactionDate: '',
      Invoice: '',
      Receipt: '',
      Comment: '',
    })
    setFormErrors({})
    setVendorDetail({id: '', amount: ''})
    setOpenModal(false)   
  }
  const getVendors = () => {
    getDueOverDueVendor().then((res) => {
      if (res.statusCode == 200) {
        setVendor(res.data)
        setFilterVendor(res.data)

      } else {
        setVendor([])
        setFilterVendor([])
        Message(res.message, 'error')
      }
    })
  }


  const getThisWeek = () => {
    getAllThisweek().then((res) => {
      if (res.statusCode == 200) {
        setThisweek(res.data)
        setFilterThisWeek(res.data)
      } else {
        setThisweek([])
        setFilterThisWeek([])
        Message(res.message, 'error')
      }
    })
  }

  const filterData = (data: any, tabName: string) => {
    let vendorFilterData = vendor.filter((item: any) => {
      return item.id == data.id
    })
    let overDueFilterData = overdue.filter((item: any) => {
      return item.id == data.id
    })
    let thisWeekFilterData = thisweek.filter((item: any) => {
      return item.id == data.id
    })
    setFormValues({
      ...formValues,
      duePayments: data.name,
      overDuePayment: data.name,
      serviceProvider: data.name,
      /*  isDisableDuePayments: tabName==='this_week'?'current':'true',
 isDisableOverDuePayments: tabName==='over_due'?'current':'true',
isDisableSp: tabName==='service_provider'?'current':'true', */
    })
    // if(tabName==='service_provider'){
    //   setFilterOverdue(overDueFilterData)
    // }else if(tabName === 'this_week'){
    setFilterVendor(vendorFilterData)
    setFilterThisWeek(thisWeekFilterData)
    setFilterOverdue(overDueFilterData)
    // }else{
    //   setFilterThisWeek(thisWeekFilterData)
    //   setFilterVendor(vendorFilterData)
    // }
  }

  const openModalFunction = (vendors: any) => {
    console.log(vendors)
    setVendorDetail({
      ...vendorDetail,
      id: vendors?.id ?? '',
      amount: vendors?.amount ?? '',
    })
    setOpenModal(true)
  }

  const handleSubmit = (e: {preventDefault: () => void}) => {
    let {cnt, errors} = Validate(formValues)
    setFormErrors(errors)
    if (cnt == 0) {
      const formData = new FormData()
      formData.append('files', formValues.Invoice)
      formData.append('transactionDate', formValues.TransactionDate)
      formData.append('transactionId', formValues.TransactionId)
      // formData.append('files', formValues.Receipt)
      formData.append('amount', vendorDetail.amount)
      formData.append('vendor', vendorDetail.id)
      formData.append('comment', formValues.Comment)
      addTransactions(formData).then((res) => {
        if (res.statusCode == 200) {
          const formData = new FormData()
          formData.append('files', formValues.Receipt)
          addReceiptTransactions(formData,res.data.id).then((res2) => {
            if (res2.statusCode == 200) {
              toast.success(res2.message)
              // getTransaction()
              setOpenModal(false)
            } else {
              Message(res2.message, 'error')
            }
          })
        }
      })
    }
  }


  const handleSearch = (e: React.SyntheticEvent) => {
    let target = e.target as HTMLInputElement
    setFormValues({
      ...formValues,
      [target.name]: target.value,
      duePayments: target.name === 'duePayments' ? target.value : '',
      serviceProvider: target.name === 'serviceProvider' ? target.value : '',
      overDuePayment: target.name === 'overDuePayment' ? target.value : '',
    })
    console.log(target.name, target.value)
    if (target.name === 'serviceProvider') {
      let vendorFilterData = vendor.filter(
        (item: any) =>
          item?.location?.toString()?.toLowerCase()?.includes(target.value.toLowerCase()) ||
          item?.name?.toLowerCase().includes(target.value.toLowerCase())
      )
      let dueFilterData = thisweek.filter(
        (item: any) =>
          item?.location?.toString()?.toLowerCase()?.includes(target.value.toLowerCase()) ||
          item?.name?.toLowerCase()?.includes(target.value.toLowerCase())
      )
      let overDueFilterData = overdue.filter(
        (item: any) =>
          item?.location?.toString()?.toLowerCase()?.includes(target.value.toLowerCase()) ||
          item?.name?.toLowerCase()?.includes(target.value.toLowerCase())
      )
      setFilterVendor(vendorFilterData)
      setFilterThisWeek(dueFilterData)
      setFilterOverdue(overDueFilterData)
      console.log('In Service', vendorFilterData, dueFilterData, overDueFilterData)
    } else {
      let vendorFilterData = vendor.filter(
        (item: any) =>
          item?.amount?.toString()?.toLowerCase()?.includes(target.value.toLowerCase()) ||
          item?.name?.toLowerCase()?.includes(target.value.toLowerCase())
      )
      let dueFilterData = thisweek.filter(
        (item: any) =>
          item?.amount?.toString()?.toLowerCase()?.includes(target.value.toLowerCase()) ||
          item?.name?.toLowerCase()?.includes(target.value.toLowerCase())
      )
      let overDueFilterData = overdue.filter(
        (item: any) =>
          item?.amount?.toString()?.toLowerCase()?.includes(target.value.toLowerCase()) ||
          item?.name?.toLowerCase()?.includes(target.value.toLowerCase())
      )
      setFilterVendor(vendorFilterData)
      setFilterThisWeek(dueFilterData)
      setFilterOverdue(overDueFilterData)
      console.log('In Other', vendorFilterData, dueFilterData, overDueFilterData)
    }
  }

  const handleChange = (e: React.SyntheticEvent, formValues: any) => {
    let target = e.target as HTMLInputElement
    if (target.name == 'Invoice' || target.name == 'Receipt') {
      const file = target.files
      setFormValues({
        ...formValues,
        [target.name]: file == null ? '-' : file[0],
      })
    } else {
      setFormValues({
        ...formValues,
        [target.name]: target.value,
      })
    }
  }
  const handleClear = () => {
    setFormValues({
      ...formValues,
      duePayments: '',
      overDuePayment: '',
      serviceProvider: '',
      isDisableDuePayments: 'false',
      isDisableOverDuePayments: 'false',
      isDisableSp: 'false',
    })
    setFilterOverdue(overdue)
    setFilterThisWeek(thisweek)
    setFilterVendor(vendor)
  }
  

  useEffect(() => {
    // mounted for API should not call again and again
    let mounted = true
    if (mounted) {
      getThisWeek()
      getOverdue()
      getVendors()
    }
    return () => {
      mounted = false
    }
  }, [])

  function Validate(values: {
    TransactionId: string
    TransactionDate: string
    Invoice: any
    Receipt: any
  }): any {
    const errors: any = {}
    const currentDate: Date = new Date()
    let cnt = 0
    if (!values.TransactionId) {
      cnt = cnt + 1
      errors.TransactionId = 'transaction id is required !'
    }
    if (!values.TransactionDate) {
      cnt = cnt + 1
      errors.TransactionDate = 'transaction date is required !'
    } else {
      const transactionDate: Date = new Date(values.TransactionDate)
      if (transactionDate >= currentDate) {
        cnt = cnt + 1
        errors.TransactionDate = 'Transaction date should not be a future date.'
      }
    }

    if (!values.Invoice) {
      cnt = cnt + 1
      errors.Invoice = 'Invoice is required !'
    }

    let fileregex = /^.*\.(png|pdf|jpeg|txt)$/i
    if (!values.Invoice) {
      cnt = cnt + 1
      errors.Invoice = 'Invoice is Required !'
    } else {
      if (!fileregex.test(values.Invoice.name)) {
        cnt = cnt + 1
        errors.Invoice = 'Please select png, jpeg, txt, or PDF file'
      }
    }

    if (!values.Receipt) {
      cnt = cnt + 1
      errors.Receipt = 'Receipt is Required !'
    } else {
      if (!fileregex.test(values.Receipt.name)) {
        cnt = cnt + 1
        errors.Receipt = 'Please select png, jpeg, txt, or PDF file'
      }
    }

    return {errors, cnt}
  }

  return (
    <div className='container'>
      <div className='row equal-cols'>
        <div className='col-4 ' >
          <div className='p-3 border border-success  rounded' style={{height:'400px', overflowY:'scroll' }}>
          <style>
    {`
      .p-3::-webkit-scrollbar{
        width: 10px;
        // background-color: blue;
        // scrollbar-color:blue;
      }

      .p-3::-webkit-scrollbar-thumb:active{
        background-color: grey;
      } 
      .p-3::-webkit-scrollbar-thumb{
        background-color: grey;
      } 
      .p-3::-webkit-scrollbar-thumb:hover {
        background-color: grey; /* set the color you want */
      }
      /* .p-3::-webkit-scrollbar-thumb:focus {
        background-color: #ff0000; /* set the color you want */
        scrollbar-color:blue;

      } */
     /*  .p-3::-webkit-scrollbar-track {
         background-color: grey;
      } */
      /* .p-3::-webkit-scrollbar-track:focus {
        background-color: yellow;
      } */
  
    `}
  </style>
            <div className='d-flex justify-content-between align-items-center'>
              <h4>Service Provider</h4>
              <div className={`${formValues.isDisableSp === 'current' ? 'col-5' : 'col-6'}`}>
                <SearchBar
                  name='serviceProvider'
                  isDisable={formValues.isDisableSp}
                  fieldValue={formValues.serviceProvider}
                  onChangeFunction={handleSearch}
                  onClearFunction={handleClear}
                />
              </div>
            </div>

            {filterVendor.length <= 0 ? (
              <>
                <div className='text-center py-3'>No Records Available</div>
              </>
            ) : (
              filterVendor.sort((a:any,b:any) => a.name.localeCompare(b.name)).map(
                (obj: {
                  id: string
                  name: string
                  amount: string
                  due_date: string
                  location: string
                }) => {
                  return (
                    <>
                      <div className='row border rounded border-dark p-2 my-3 mx-1 bg-light align-items-center'>
                        <div className='col-9' onClick={() => filterData(obj, 'service_provider')}>
                          Name: {obj.name}
                          <br />
                          Location: {obj.location}
                        </div>
                        {/* <div
                        className='col-3'
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <button
                          type='button'
                          className='btn btn-primary btn-sm  '
                          onClick={() => {
                            openModalFunction(obj)
                            setOpenModal(true)
                          }}
                        >
                          Pay
                        </button>
                      </div> */}
                      </div>
                    </>
                  )
                }
              )
            )}
          </div>
        </div>
        <div className='col-4'>
          <div className='p-3 border border-warning  rounded' style={{height:'400px', overflowY:'scroll'}} >
            <div className='d-flex justify-content-between align-items-center' >
              <h4>Due Payments</h4>
              <div className='col-5'>
                <SearchBar
                  name='duePayments'
                  isDisable={formValues.isDisableDuePayments}
                  fieldValue={formValues.duePayments}
                  onChangeFunction={handleSearch}
                  onClearFunction={handleClear}
                />
              </div>
            </div>
            {/* <div className='row p-3'>
            <div className='col-12 border rounded p-1 my-1'>
              <h5>Client ABC</h5>
              <h6>24 February , 2023 | 04:00 PM</h6>
            </div>
          </div> */}
            {filterThisWeek.length <= 0 ? (
              <>
                <div className='text-center py-3'>No Records Available</div>
              </>
            ) : (
              filterThisWeek.sort((a:any, b:any) => {
                const dateA = new Date(a.due_date);
                const dateB = new Date(b.due_date);
                return dateA.getTime() - dateB.getTime();
              }).map(
                (obj: {id: string; name: string; amount: string; due_date: string}) => {
                  return (
                    <>
                      <div className='row border rounded border-dark p-2 my-3 mx-1 bg-light align-items-center'>
                        <div className='col-9' onClick={() => filterData(obj, 'this_week')}>
                          Name:{obj.name}
                          <br />
                          <span
                            className={`${
                              Math.abs(calculateDateDifference(new Date(), obj?.due_date ?? '-')) ==
                              0
                                ? 'label label-inline badge badge-light-danger fw-bold py-2 fs-7 mx-0'
                                : ''
                            }`}
                          >
                            Due by
                            {Math.abs(calculateDateDifference(new Date(), obj?.due_date ?? '-')) !==
                            0
                              ? ` ${Math.abs(
                                  calculateDateDifference(new Date(), obj?.due_date ?? '-')
                                )} days`
                              : ' Today'}
                          </span>
                          <br /> RS:{obj.amount}
                        </div>
                        <div
                          className='col-3'
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <button
                            type='button'
                            className='btn btn-primary btn-sm  '
                            onClick={() => {
                              openModalFunction(obj)
                              setOpenModal(true)
                            }}
                          >
                            Pay
                          </button>
                        </div>
                      </div>
                    </>
                  )
                }
              )
            )}
          </div>
        </div>
        <div className='col-4' >
          <div className='p-3 border border-danger  rounded'  style={{height:'400px', overflowY:'scroll' }} >
            <div className='d-flex justify-content-between align-items-center'>
              <h4>Over Due Payments</h4>
              <div className='col-5'>
                <SearchBar
                  name='overDuePayment'
                  isDisable={formValues.isDisableOverDuePayments}
                  fieldValue={formValues.overDuePayment}
                  onChangeFunction={handleSearch}
                  onClearFunction={handleClear}
                />
              </div>
            </div>
            {/* <div className='row p-3'>
            <div className='col-12 border rounded p-1 my-1'>
              <h5>Client ABC</h5>
              <h6>24 February , 2023 | 04:00 PM</h6>
            </div>
          </div> */}
            {filterOverdue.length <= 0 ? (
              <>
                <div className='text-center py-3'>No Records Available</div>
              </>
            ) : (
              filterOverdue.sort((a:any, b:any) => {
                const dateA = new Date(a.due_date);
                const dateB = new Date(b.due_date);
                return  dateA.getTime() - dateB.getTime() ;
              }).map(
                (obj: {id: string; name: string; amount: string; due_date: string}) => {
                  return (
                    <>
                      <div className='row border rounded border-dark p-2 my-3 mx-1 bg-light align-items-center'>
                        <div className='col-9' onClick={() => filterData(obj, 'over_due')}>
                          Name:{obj.name}
                          <br /> Over due by{' '}
                          {Math.abs(calculateDateDifference(new Date(), obj?.due_date ?? '-'))} days
                          <br /> RS:{obj.amount}
                        </div>
                        <div
                          className='col-3'
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <button
                            type='button'
                            className='btn btn-primary btn-sm  '
                            onClick={() => {
                              openModalFunction(obj)
                              setOpenModal(true)
                            }}
                          >
                            Pay
                          </button>
                        </div>
                      </div>
                    </>
                  )
                }
              )
            )}
          </div>
        </div>
      </div>
      <Modal
        className='custom-modal-siz'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={openModal}
        onHide={() => handleCancel()}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Transaction Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='mb-3'>
              <label className='form-label'>Transaction ID</label>
              <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
              <input
                type='text'
                className='form-control'
                placeholder='Enter transaction id'
                name='TransactionId'
                value={formValues.TransactionId}
                onChange={(e) => handleChange(e, formValues)}
              />
              {<InputError Message={formErrors.TransactionId} />}
            </div>
            <div className='mb-3'>
              <label className='form-label'>Transaction Date</label>
              <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
              <input
                type='date'
                className='form-control'
                placeholder=' Enter transaction Date'
                name='TransactionDate'
                value={formValues.TransactionDate}
                onChange={(e) => handleChange(e, formValues)}
              />
              {<InputError Message={formErrors.TransactionDate} />}
            </div>
            <div className='mb-3'>
              <label className='form-label'>Invoice</label>
              <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
              <input
                type='file'
                className='form-control'
                id='Invoice'
                name='Invoice'
                onChange={(e) => handleChange(e, formValues)}
              />
              {<InputError Message={formErrors.Invoice} />}
            </div>
            <div className='mb-3'>
              <label className='form-label'>Receipt</label>
              <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
              <input
                type='file'
                className='form-control'
                id='Receipt'
                name='Receipt'
                onChange={(e) => handleChange(e, formValues)}
              />
              {<InputError Message={formErrors.Receipt} />}
            </div>

            <div className='mb-3'>
              <label className='form-label'>Comment</label>
              <textarea
                className='form-control'
                id='exampleFormControlTextarea1'
                // rows={50}
                name='Comment'
                onChange={(e) => handleChange(e, formValues)}
                style={{height: '100px'}}
              ></textarea>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-secondary btn-sm' onClick={() => handleCancel()}>
            Cancel
          </button>
          <button className='btn btn-primary btn-sm' onClick={(e) => handleSubmit(e)}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
