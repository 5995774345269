import {API_BASE_URL} from '../../Constant'
import {setupAxios} from '../pages/auth'

export function getAllSupports() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/supports', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

export function UpSystem(id: any) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/server-logs/${id}`, {
    method: 'PUT',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

export function DownSystem(id: any) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/server-logs`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      support: id,
    }),
  }).then((res) => res.json())
}

export function addTransactions() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/transactions', {
    method: 'POST',

    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

export function getServerlogsBySupportId(supportId: string) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/server-logs/support/${supportId}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}
