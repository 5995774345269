import React from 'react'

const InputError = (props: {Message: string; className?: string}) => {
  return (
    <div className='fv-plugins-message-container'>
      <div className='fv-help-block'>
        <div className={`text-danger ${props.className}`}></div>
        <span role='alert'>{props.Message}</span>
      </div>
    </div>
  )
}
export default InputError
