import {Suspense, useEffect} from 'react'
import {Outlet, useLocation} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {AuthInit, useAuth} from './pages/auth'
import 'react-toastify/dist/ReactToastify.css'
import { isTokenExpired } from '../_metronic/helpers/AssetHelpers'
import Swal from 'sweetalert2'
import * as authHelper from '../app/pages/auth/core/AuthHelpers'
import { refreshToken } from './pages/auth/core/_requests'


const App = () => {
  let checkUserSession = sessionStorage.getItem('isLoggedIn')
  const storedIntervalId: any = localStorage.getItem('intervalId')
  const {currentUser, logout,saveAuth,setCurrentUser} = useAuth()
  const location = useLocation()


  useEffect(() => {
    const {token, refreshToken} = authHelper.setupAxios()

    if (checkUserSession == null) {
      logout()
    }
    if (isTokenExpired(token)) {
      if (
        location.pathname !== '/auth' &&
        location.pathname !== '/auth/forgot-password' &&
        location.pathname !== 'auth/reset-password'
      ) {
        Swal.fire({
          icon: 'warning',
          title: 'Your session has expired. Please login to continue',
          heightAuto: false,
        }).then((result: any) => {
          logout()
        
        })
      } 
    }
  }, [])
  useEffect(() => {
    const storedIntervalId: any = localStorage.getItem('intervalId')
    const intervalId: any = parseInt(storedIntervalId)
    if (storedIntervalId) {
      setInterval(() => {
        refreshToken().then((res) => {
          if (res.statusCode == 200) {
            const storageItem: any = localStorage.getItem('kt-auth-react-v')
            const user: any = JSON.parse(storageItem)
            // Update a property of the object
            user.accessToken = res.data.accessToken
            user.refreshToken = res.data.refreshToken
            // Set the updated object back into local storage
            saveAuth(user)
            setCurrentUser(user)
          } else {
            clearInterval(intervalId)
          }
        })
      }, 150000)
    } else {
      clearInterval(intervalId)
    }
  }, [storedIntervalId])








  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ToastContainer
            position='top-center'
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <AuthInit>
            <Outlet />
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
