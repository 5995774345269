import React, {useEffect, useState} from 'react'
import {DefaultDataTableOption} from '../../../data/DataTableData'
import {getAllNotifications, updateNotification} from '../../API/NotificationAPI'
import {getAllVendors} from '../../API/ServicesAPI'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.css'
import {changeTextCapital, Message} from '../../../_metronic/helpers'
import { toast } from 'react-toastify'
const $ = require('jquery')

function Notification() {
  const [notification, setNotification] = useState([])
  const [vendors, setVendors] = useState([])
  
  

  const getNotification = () => {
    getAllNotifications().then((res) => {
      if (res.statusCode == 200) {
        setNotification(res.data)
        $(document).ready(function () {
          $('#table_id1').DataTable({
            ...DefaultDataTableOption,
            order: [[0, 'asc']],
          })
        })
      } else {
        setNotification([])
      }
    })
  }


  const handleTabChange = (e:any, id:string) => {
    console.log(e.target.checked, e.target.name,id)

      updateNotification(id,e.target.checked).then((res) => {
        if (res.statusCode == 200) {
          getNotification()
          toast.success(res.message)
        } else {
          Message(res.message, 'error')
        }
      })

  }



  const getVendors = () => {
    getAllVendors().then((res) => {
      if (res.statusCode == 200) {
        setVendors(res.data)
      }
    })
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      getNotification()
      getVendors()
    }
    return () => {
      mounted = false
    }
  }, [])

  return (
    <div>
      {' '}
      <div className='card'>
        <div className='card-header mt-5'>
          <span className='fs-1 '>Notifications</span>
          <div></div>
        </div>
        <div className='card-body '>
          <div className='table-responsive'>
            <table
              className='table table-row-dashed table-row-gray-300 align-middle gs-0 table-bordered '
              id='table_id1'
            >
              {/* <table className='table' id='table_id1'> */}
              <thead className='text-centre'>
                <tr className='fw-bold text-muted'>
                  <th
                    className='pl-1'
                    style={{
                      minWidth: '110px',
                      width: '100px',
                    }}
                  >
                    {changeTextCapital('SP NAME')}
                  </th>
                  <th
                    className='pl-1'
                    style={{
                      width: '100px',
                    }}
                  >
                    {changeTextCapital('Date')}
                  </th>
                  <th
                    className='pl-1'
                    style={{
                      width: '100px',
                    }}
                  >
                    {changeTextCapital('Due Date')}
                  </th>

                  <th
                    className='pl-1'
                    style={{
                      width: '100px',
                    }}
                  >
                    {changeTextCapital('Over Due Date')}
                  </th>
                  
                  <th
                    className='pl-1'
                    style={{
                      width: '100px',
                    }}
                  >
                    {changeTextCapital('Amount')}
                  </th>
                  
      
                  <th
                    className='pl-1'
                    style={{
                      width: '100px',
                    }}
                  >
                    {changeTextCapital('NOTIFY')}
                  </th>

                </tr>
              </thead>
              <tbody className='text-gray-800 fw-semibold text-left'>
                {notification.map(
                  (obj: {
                    // id: string
                    date: string
                    // dueDate: string
                    vendor: {id:string; name: String; dueDate: string; amount:string; emailTogal:boolean}
                    // bankDetail: null
                    //Name: string

                  }) => {
                    return (
                      <tr>
                        <td>{obj.vendor?.name ?? ''}</td>
                        <td>{obj.date}</td>
                        <td>{new Date(obj?.vendor?.dueDate) > new Date() ? obj.vendor.dueDate : '-'}</td>
                        <td>{new Date(obj?.vendor?.dueDate) < new Date() ? obj.vendor.dueDate : '-' }</td>
                        <td>{obj.vendor?.amount ?? ''}</td>

                        <td className=''>
                          
                        <div className='form-check form-switch'>
                        <input
                        
                                // className='form-check-input '
                                className={`form-check-input ${obj.vendor.emailTogal ? 'bg-success' : 'bg-secondary'}`}
                                type='checkbox'
                                name={obj.vendor.id}
                                defaultChecked={obj.vendor.emailTogal}
                                checked={obj.vendor.emailTogal}
                                id='flexSwitchCheckDefault'
                               onChange={(e) => handleTabChange(e, obj.vendor.id)}
                              
                              />
                              <label
                                className='form-check-label'
                                htmlFor='flexSwitchCheckDefault'
                              ></label>
                              </div>
                       

                      </td>


                      </tr>
                    )
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Notification
