import React, {useEffect} from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import {useState} from 'react'
import {getAllVendors} from '../../../../app/API/ServicesAPI'
import {getAlloverDue} from '../../../../app/API/calenderAPI'
import {getAllPaid} from '../../../../app/API/GridAPI'
import {getDate} from '../../../helpers'

const Calendar = () => {
  const handleEventClick = (info: any) => {
    console.log(info.event)
  }
  const {currentDate} = getDate()

  const [events, setEvents] = useState([])
  const [overdue, setOverdue] = useState([])
  const [paid, setPaid] = useState([])
  const data = [
    ['Total Upcoming amount', 5500],
    ['Paid amount', 3500],
    ['Overdue amount', 2000],
  ]

  const getOverdue = () => {
    getAlloverDue().then((res) => {
      if (res.statusCode == 200) {
        let overdue: any = []
        res.data.map((obj: any) => {
          overdue.push({
            title: `${obj.name}- RS.${obj.amount}`,
            date: obj.overDue,
            color: '#FF0000',
          })
        })
        setOverdue(overdue)
      }
    })
  }
  const getPaid = () => {
    getAllPaid().then((res) => {
      if (res.statusCode == 200) {
        let Paid: any = []
        res.data.map((obj: any) => {
          Paid.push({
            title: `${obj.name}- RS.${obj.amount}`,
            date: obj.PaidDate,
            color: obj.isPaid ? 'green' : obj.dueDate < currentDate ? 'red' : '#FFDB58',
          })
        })
        setPaid(paid)
      }
    })
  }
  const eventRender = (info:any) => {
    if (info.event.extendedProps.highlight) {
      info.el.classList.add('highlight-event')
    }
  }

  // API call to add events in calendar
  useEffect(() => {
    getOverdue()
    getPaid()
    let mounted = true
    if (mounted) {
      getAllVendors().then((res) => {
        if (res.statusCode == 200) {
          let event: any = []
          res.data.map((obj: any) => {
            event.push({
              title: `${obj.name}- RS.${obj.amount}`,
              date: obj.dueDate,
              color: obj.isPaid ? 'green' : obj.dueDate < currentDate ? 'red' : 'yellow',
              highlight:  obj.dueDate < currentDate ? true : false,
            })
          })
          setEvents(event)
        }
      })
    }
    return () => {
      mounted = false
    }
  }, [])
  return (
    <div className='card'>
      <FullCalendar
        plugins={[dayGridPlugin]} //plugins for calendar access
        initialView='dayGridMonth'
        events={events}
        eventClick={handleEventClick}
        dayMaxEventRows={0}
       
      />
    </div>
  )
}

export default Calendar
function setEvents(event: any) {
  throw new Error('Function not implemented.')
}

function setOverdue(overdue: any) {
  throw new Error('Function not implemented.')
}
