import React, {createRef, useEffect, useState} from 'react'
import Select from 'react-select'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.css'
import InputError from '../../../_metronic/layout/components/InputError'
import {DefaultDataTableOption} from '../../../data/DataTableData'
import {Modal} from 'react-bootstrap'
import {addUsers, deleteUsers, getAllUsers, getUsersById, setNotification, updateUserById} from '../../API/UsersAPI'
import {getAllRoles} from '../../API/RolesAPI'
import Swal from 'sweetalert2'
import {changeTextCapital, Message} from '../../../_metronic/helpers'
import {AlertProps} from '../../../data/AlertDefalt'
import {DELETE_MESSAGE, UPDATE_MESSAGE} from '../../../Constant'
import {result} from 'lodash'
import {toast} from 'react-toastify'
import {ResetPassword} from '../auth/components/ResetPassword'
import {Registration} from '../auth/components/Registration'
import {ChangePassword} from './ChangePassword'

const $ = require('jquery')

const initialValues = {
  FirstName: '',
  LastName: '',
  Phone: '',
  Email: '',
  // Company: '',
  // Roles: [{label: 'Select Roles', value: ''}],
  Role: '',
}

function NewUser() {
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState<any>({})
  const [editId, seteditId] = useState<string | null>(null)
  const [isSubmit, setIsSubmit] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openModal1, setOpenModal1] = useState(false)
  const [showResetForm, setShowResetForm] = useState(false)
  const [user, setUser] = useState([
    {
      company: '',
      email: '',
      firstName: '',
      id: '',
      lastName: '',
      password: '',
      phone: ' ',
      userRoles: [{id: '', roles: ''}],
      emailTogal:true
    },
  ])

  const [role, setRole] = useState([{id: '', roles: ''}])
  // const [role, setRole] = useState([{roles: '', id: ''}])
  const handleChange = (e: {target: {name: any; value: any}}) => {
    const {name, value} = e.target
    setFormValues({...formValues, [name]: value})
  }
  const handleOpenResetForm = () => {
    setShowResetForm(true)
  }

  const handleCloseResetForm = () => {
    setShowResetForm(false)
  }

  const handleCancel = () => {
    setFormValues(initialValues)
    setFormErrors({})
    setOpenModal(false)
    setOpenModal1(false)
  }

  const getUserById = (id: string) => {
    getUsersById(id).then((res) => {
      if (res.statusCode == 200) {
        setFormValues({
          ...formValues,
          FirstName: res?.data?.firstName ?? '',
          LastName: res?.data?.lastName ?? '',
          Phone: res?.data?.phone ?? '',
          Email: res?.data?.email ?? '',
          // Company: res?.data?.company ?? '',
          Role: res?.data?.userRoles[0]?.id ?? '',
        })
        setOpenModal(true)
        // setOpenModal1(true)

        seteditId(id)
      } else {
        seteditId(null)
      }
    })
  }



  const handleTabChange = (e:any, id:string) => {
    console.log(e.target.checked, e.target.name)

      setNotification(id,e.target.checked).then((res) => {
        if (res.statusCode == 200) {
          getUser()
          toast.success(res.message)
        } else {
          Message(res.message, 'error')
        }
      })

  }


  const deleteUser = (id: string | undefined) => {
    // Swal.fire({...AlertProps, icon: 'warning', title: DELETE_MESSAGE}).then((result: any) => {
    Swal.fire({...AlertProps, icon: 'warning', title: DELETE_MESSAGE}).then((result: any) => {
      if (result.isConfirmed) {
        deleteUsers(id).then((res) => {
          if (res.statusCode == 200) {
            $('#table_id1').dataTable().fnDestroy()
            toast.success(res.message)
            getUser()
          } else {
            Message(res.message, 'error')
          }
        })
      }
    })
  }
  const handleSubmit = (e: {
    preventDefault: (seteditId: Function, editId: string | null) => void
  }) => {
    let {cnt, errors} = validate(formValues)
    setFormErrors(errors)
    console.log(editId)
    if (cnt == 0) {
      if (editId == null) {
        addUsers(formValues).then((res) => {
          if (res.statusCode == 200) {
            toast.success(res.message)
            $('#table_id1').dataTable().fnDestroy()
            getUser()
            setOpenModal(false)
            // setOpenModal1(false)
          } else {
            Message(res.message, 'error')
          }
        })
      } else {
        Swal.fire({...AlertProps, icon: 'warning', title: UPDATE_MESSAGE}).then((result: any) => {
          if (result.isConfirmed) {
            updateUserById(formValues, editId).then((res) => {
              if (res.statusCode == 200) {
                getUser()
                toast.success(res.message)
                $('#table_id1').dataTable().fnDestroy()
                setOpenModal(false)
                // setOpenModal1(false)
              } else {
                Message(res.message, 'error')
              }
            })
          }
        })
      }
    }
  }

  const getUser = () => {
    getAllUsers().then((res) => {
      if (res.statusCode == 200) {
        setUser(res.data)
        $(document).ready(function () {
          $('#table_id1').DataTable({
            ...DefaultDataTableOption,
            order: [[0, 'asc']],
          })
        })
      }
    })
  }

  const getroles = () => {
    getAllRoles().then((res) => {
      if (res.statusCode == 200) {
        setRole(res.data)
      }
    })
  }
  const addNew = () => {
    setFormValues(initialValues)
    setFormErrors({})
    seteditId(null)
    setOpenModal(true)
    // setOpenModal1(true)
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      getUser()
      getroles()
    }
    return () => {
      mounted = false
    }
  }, [])

  function validate(values: {
    FirstName: string
    LastName: string
    Phone: string
    Email: string
    Role: string
    // UserId: string
    // OldId: string
    // NewId: string

    // Roles: [{label: string; value: string}]
  }): any {
    const errors: any = {}
    let cnt = 0
    // console.log('in validate error')

    let alphaRegex = /^[A-Za-z ]+$/

    if (!values.FirstName) {
      cnt = cnt + 1
      errors.FirstName = 'FirstName is required !'
    } else {
      if (!alphaRegex.test(values.FirstName)) {
        cnt = cnt + 1
        errors.FirstName = 'Enter only alphabates !'
      }
    }
    if (!values.LastName) {
      cnt = cnt + 1
      errors.LastName = 'LastName id is required !'
    } else {
      if (!alphaRegex.test(values.LastName)) {
        cnt = cnt + 1
        errors.LastName = 'Enter only alphabates !'
      }
    }
    let phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/
    if (!values.Phone) {
      cnt = cnt + 1
      errors.Phone = 'Phone is required !'
    } else {
      if (!phoneRegex.test(values.Phone)) {
        cnt = cnt + 1
        errors.Phone = 'Enter only 10 digit !'
      }
    }

    if (!values.Role) {
      cnt = cnt + 1
      errors.Role = 'role id is required !'
    }
    // if (!values.UserId) {
    //   cnt = cnt + 1
    //   errors.UserId = 'User id is required !'
    // }
    // if (!values.OldId) {
    //   cnt = cnt + 1
    //   errors.OldId = 'Old Password is required !'
    // }
    // if (!values.NewId) {
    //   cnt = cnt + 1
    //   errors.NewId = 'New Password is required !'
    // }

    let emailRegex =
      /^[a-z0-9]+(?!.*(?:\+{1,}|\-{1,}|\.{2,}))(?:[\.+\-]{0,1}[a-z0-9])*@(?:skeletos\.(?:in|io))$/
    if (!values.Email) {
      cnt = cnt + 1
      errors.Email = 'Email is Required'
    } else {
      if (!emailRegex.test(values.Email.trim())) {
        if (
          values.Email.charAt(values.Email.split('@')[0].length - 1) == '.' ||
          values.Email.charAt(0) == '.'
        ) {
          cnt = cnt + 1
          errors.Email =
            'Last or First character of the prefix of email must be letter (a-z) or number (0-9)'
        } else {
          cnt = cnt + 1
          errors.Email =
            'Only letters (a-z),numbers (0-9),and periods (.) are allowed in prefix of email and skeletos.in/skeletos.io are allowed in domain of email.'
        }
      } else if (!/[a-z]/gm.test(values.Email.split('@')[0])) {
        if (values.Email.replace('.', '')?.length >= 8) {
          cnt = cnt + 1
          errors.Email =
            'Email prefix of 8 or more characters must include at least one alphabetical character (a-z)'
        }
      } else {
        if (values.Email.replaceAll(' ', '').length < 6) {
          cnt = cnt + 1
          errors.Email = 'Email must be greater than 6 characters'
        }
        if (values.Email.replaceAll(' ', '').length > 75) {
          cnt = cnt + 1
          errors.Email = 'Email should not be greater than 75 characters'
        }
      }
    }

    return {errors, cnt}
  }

  return (
    <div>
      <div className='card'>
        <div className='card-header mt-5'>
          <span className='fs-1 '>User</span>
          <div>
            <button type='button' className='btn btn-primary btn-sm' onClick={() => addNew()}>
              Add New
            </button>
          </div>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table
              className='table table-row-dashed table-row-gray-300 align-middle gs-0 table-bordered '
              id='table_id1'
            >
              {/* <table className='table' id='table_id1'> */}
              <thead className='text-centre'>
                <tr className='fw-bold text-muted'>
                  {/* <th scope='col'>No</th> */}
                  <th
                    className='pl-1'
                    style={{
                      minWidth: '100px',
                      // width: '50px',
                    }}
                  >
                    {changeTextCapital('First Name')}
                  </th>
                  <th
                    className='pl-1'
                    style={{
                      minWidth: '100px',
                      // width: '50px',
                    }}
                  >
                    {changeTextCapital('Last Name')}
                  </th>
                  <th
                    className='pl-1'
                    style={{
                      minWidth: '100px',
                      // width: '50px',
                    }}
                  >
                    {changeTextCapital('Phone')}
                  </th>
                  <th
                    className='pl-1'
                    style={{
                      minWidth: '150px',
                      // width: '50px',
                    }}
                  >
                    {changeTextCapital('Email')}
                  </th>
                  <th
                    className='pl-1'
                    style={{
                      minWidth: '100px',
                      // width: '50px',
                    }}
                  >
                    {changeTextCapital('Company')}
                  </th>
                  <th
                    className='pl-1'
                    style={{
                      minWidth: '100px',
                      // width: '50px',
                    }}
                  >
                    {changeTextCapital('Role')}
                  </th>


                  <th
                    className='pl-1'
                    style={{
                      minWidth: '50px',
                      width: '50px',
                    }}
                  >
                    {changeTextCapital('Notify')}
                  </th>


                  <th
                    className='pl-1'
                    style={{
                      minWidth: '130px',
                      // width: '50px',
                    }}
                  >
                    {changeTextCapital('Action')}
                  </th>

                </tr>
              </thead>
              <tbody>
                {user.map(
                  (obj: {
                    id: string
                    firstName: string
                    lastName: string
                    phone: string
                    email: string
                    company: string
                    userRoles: {id: string; roles: string}[]
                    emailTogal: boolean
                    // userRoles: string
                    // userRoles: [{label: string; value: string}]
                  }) => {
                    return (
                      <tr key={obj.id}>
                        <td>{obj.firstName}</td>
                        <td>{obj.lastName}</td>
                        <td>{obj.phone}</td>
                        <td>{obj.email}</td>
                        <td>{obj.company}</td>
                    

                        <td>
                          {obj.userRoles.map((obj) => {
                            return obj.roles
                          })}
                        </td>

                        <td>
                        <div className='form-check form-switch'>
                        <input
                        
                                // className='form-check-input '
                                className={`form-check-input ${obj.emailTogal ? 'bg-success' : 'bg-secondary'}`}
                                type='checkbox'
                                name={obj.id}
                                defaultChecked={obj.emailTogal}
                                checked={obj.emailTogal}
                                id='flexSwitchCheckDefault'
                               onChange={(e) => handleTabChange(e, obj.id)}
                              
                              />
                              <label
                                className='form-check-label'
                                htmlFor='flexSwitchCheckDefault'
                              ></label>
                              </div>
                          </td>


                        <td className=''>
                          <button
                            className='btn btn-icon btn-bg-light btn-sm me-1'
                            onClick={() => getUserById(obj.id)}
                          >
                            <i className='fa-solid fa-pen-to-square '></i>
                          </button>
                          <button
                            className='btn btn-icon btn-bg-light btn-sm me-1'
                            onClick={() => deleteUser(obj.id)}
                          >
                            <i className='fa-solid fa-trash'></i>
                          </button>
                          <button
                            className='btn btn-icon btn-bg-light btn-sm me-1'
                            onClick={() => {
                              setOpenModal1(true)
                              seteditId(obj.email)
                              const searchInput = $('div.dataTables_filter input')
                              searchInput.val(obj.email)
                              searchInput.trigger('keyup')
                            }}
                          
                          >
                            <i className='fa-sharp fa-solid fa-unlock'></i>
                          </button>
                          {/* {<ResetPassword />} */}
                        </td>
                      </tr>
                    )
                  }
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={openModal}
        onHide={() => handleCancel()}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>User Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className=''>
            <div className='row'>
              <div className=' '>
                <label htmlFor='MajorSP' className='form-label'>
                  {' '}
                  First Name
                </label>
                <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                <input
                  className='form-control form-control-sm '
                  type='text'
                  placeholder='Enter your FirstName'
                  name='FirstName'
                  value={formValues.FirstName}
                  onChange={handleChange}
                />
                {<InputError Message={formErrors.FirstName} />}
              </div>

              <div className=' '>
                <label htmlFor='Name' className='form-label'>
                  {' '}
                  Last Name
                </label>
                <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                <input
                  className='form-control form-control-sm'
                  type='text'
                  placeholder='Enter your LastName'
                  name='LastName'
                  value={formValues.LastName}
                  onChange={handleChange}
                />
                {<InputError Message={formErrors.LastName} />}
              </div>
              <div className=' '>
                <label htmlFor='Phone' className=' form-label'>
                  Phone
                </label>
                <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                <input
                  className='form-control form-control-sm'
                  type='number'
                  placeholder='Enter your Contact No '
                  name='Phone'
                  value={formValues.Phone}
                  onChange={handleChange}
                />
                {<InputError Message={formErrors.Phone} />}
              </div>

              <div className=' '>
                <label htmlFor='Email' className=' form-label'>
                  Email
                </label>
                <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                <input
                  className='form-control form-control-sm'
                  type='email'
                  placeholder='Enter your Email Id'
                  name='Email'
                  value={formValues.Email}
                  onChange={handleChange}
                />
                {<InputError Message={formErrors.Email} />}
              </div>

              <div className=' '>
                <label htmlFor='roles' className=' form-label'>
                  Role
                </label>
                <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                <select
                  className='form-select form-select-sm'
                  name='Role'
                  value={formValues.Role}
                  onChange={handleChange}
                >
                  <option value='' hidden>
                    Select Role
                  </option>

                  {role.map((opts, i) => (
                    <option value={opts.id}>{opts.roles}</option>
                  ))}
                </select>
                {<InputError Message={formErrors.Role} />}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-secondary btn-sm' onClick={() => handleCancel()}>
            Cancel
          </button>
          <button className='btn btn-primary btn-sm' onClick={(e) => handleSubmit(e)}>
            {editId !== null ? 'Update' : 'Save'}
          </button>
        </Modal.Footer>
      </Modal>
<ChangePassword email_id={editId} setOpenModel1={setOpenModal1} openModel1={openModal1} setEditId={seteditId} userType={'user'}/>
    </div>
  )
}
export default NewUser
