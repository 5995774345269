export const DefaultDataTableOption = {
  language: {search: '', searchPlaceholder: 'Search', lengthMenu: '_MENU_'},
  lengthMenu: [
    [10, 25, 50, -1],
    [10, 25, 50, 'All'],
  ],
  pageLength: 10,
  destroy: true,
  scrollX: true,
  screenY: true,
  saveState: true,
  // columnDefs: [
  //     { width: 200, targets: 0 }
  // ]
}
