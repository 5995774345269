import {useEffect, useState} from 'react'
import RoleContext from './roleContext'

const RoleState = (props: {children: any}) => {
  // const [state,setState]=useState('')
  // const [module,setModule]=useState([''])
  const [permissions, setPermissions] = useState([''])
  const updateState = (permissions: any) => {
    // setState(state)
    // setModule(modules)
    setPermissions(permissions)
  }

  useEffect(() => {
    const firstRole: any = localStorage.getItem('permissions')

    const Role: any = JSON.parse(firstRole)
    console.log(Role)
    if (Role !== null) {
      // setState(Role.roleName)
      // setModule(Role.modules)
      setPermissions(Role)
    }
  }, [])

  return (
    <>
      <RoleContext.Provider value={{permissions, updateState}}>
        {props.children}
      </RoleContext.Provider>
    </>
  )
}

export default RoleState
