import {API_BASE_URL} from '../../Constant'
import {setupAxios} from '../pages/auth'

export function getAllClient() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/client', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

//add posts

export function addClients(formValues: any) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/client/create', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      name: formValues.Name,
      phone: formValues.ContactNo,
      email: formValues.EmailID,
      address: formValues.Address,
      //serviceproviderID: formValues.ServiceProviderID,
    }),
  }).then((res) => res.json())
}

export function getClientsById(id: string) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/client/${id}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

//put api for client
export function updateClientById(
  formValues: {
    Name: string
    ContactNo: string
    EmailID: string
    Address: string
  },
  id: string
) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/client/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      name: formValues.Name,
      phone: formValues.ContactNo,
      address: formValues.Address,
      email: formValues.EmailID,
    }),
  }).then((res) => res.json())
}

export function deleteClients(id: string | undefined) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/client/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}
