import {API_BASE_URL} from '../../Constant'
import {setupAxios} from '../pages/auth'

export function getAllEvents() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/vendors', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

export function getAlloverDue() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/dashboard/overdue', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}
export function getAllPaid() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/dashboard/paid', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}
