import React, {useEffect, useState} from 'react'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.css'
import {getAllInvoice, getAllTransactions} from '../../API/TransactionAPI'
import {DefaultDataTableOption} from '../../../data/DataTableData'
import {getAllVendors} from '../../API/ServicesAPI'
import {changeTextCapital, Message} from '../../../_metronic/helpers/AssetHelpers'
import {API_BASE_URL} from '../../../Constant'

const $ = require('jquery')

const BillingHistory = () => {
  const [transaction, setTransactions] = useState([])
  const [vendors, setVendors] = useState([])
  const [invoice, setInvoice] = useState([])

  const getTransaction = () => {
    getAllTransactions().then((res) => {
      if (res.statusCode == 200) {
        setTransactions(res.data)
        $(document).ready(function () {
          $('#table_id1').DataTable({
            ...DefaultDataTableOption,
            order: [[0, 'asc']],
          })
        })
      } else {
        setTransactions([])
      }
    })
  }
  const getVendors = () => {
    getAllVendors().then((res) => {
      if (res.statusCode == 200) {
        setVendors(res.data)
      }
    })
  }
  const getInvoice = (id: string) => {
    getAllInvoice(id).then((res) => {
      if (res.statusCode == 200) {
        setInvoice(res.data)
      } else {
        Message(res.message, 'error')
      }
    })
  }
  useEffect(() => {
    let mounted = true
    if (mounted) {
      getTransaction()
      getVendors()
    }
    return () => {
      mounted = false
    }
  }, [])

  return (
    <div>
      <div className='card'>
        <div className='card-header mt-5'>
          <span className='fs-1 '>Billing History</span>
        </div>
        <table
          className='table table-row-dashed table-row-gray-300 align-middle gs-0 table-bordered '
          id='table_id1'
        >
          {/* <table className='table' id='table_id1'> */}
          <thead className='text-centre'>
            <tr className='fw-bold text-muted'>
              {/* <th scope='col'>No</th> */}
              <th
                className='pl-1'
                style={{
                  minWidth: '100px',
                  width: '100px',
                }}
              >
                {changeTextCapital('Name')}
              </th>
              <th
                className='pl-1'
                style={{
                  minWidth: '100px',
                  width: '100px',
                }}
              >
                {changeTextCapital('Transaction Id')}
              </th>
              <th
                className='pl-1'
                style={{
                  minWidth: '100px',
                  width: '100px',
                }}
              >
                {changeTextCapital('Transaction date')}
              </th>
              <th
                className='pl-1'
                style={{
                  minWidth: '70px',
                  width: '100px',
                }}
              >
                {changeTextCapital('amount')}
              </th>
              <th
                className='pl-1'
                style={{
                  minWidth: '70px',
                  width: '90px',
                }}
              >
                {changeTextCapital('Invoice')}
              </th>
               <th
                className='pl-1'
                style={{
                  minWidth: '100px',
                  width: '90px',
                }}
              >
                {changeTextCapital('Receipt')}
              </th> 
              <th
                className='pl-1'
                style={{
                  minWidth: '150px',
                  width: '100px',
                }}
              >
                {changeTextCapital('comment')}
              </th>
            </tr>
          </thead>
          <tbody>
            {transaction.map(
              (obj: {
                name: string
                id: string
                vendor: {name: string}
                transactionId: string
                transactionDate: string
                amount: string
                 file: string
               receipt: string
                comment: string
              }) => {
                return (
                  <tr>
                    <td>{obj?.vendor?.name ?? ''}</td>
                    <td>{obj.transactionId}</td>
                    <td>{obj.transactionDate}</td>
                    <td>{obj.amount}</td>
                    <td>
                      {obj.file!==null?    <div className='d-flex  justify-content-center flex-shrink-0'>
                        <a target='_self' href={`${API_BASE_URL}/transactions/files/${obj.id}`}>
                          <button className='btn btn-icon btn-bg-light btn-active-color-info btn-sm me-1'>
                            <i className='fa-solid fa-arrow-down-long'></i>
                          </button>
                        </a>
                      </div>:''}
                   
                       {/* {obj.file} */} 
                    </td>


                    <td>
                      {obj.receipt !== null ? <div className='d-flex  justify-content-center flex-shrink-0'>
                        <a target='_self' href={`${API_BASE_URL}/transactions/receipt/files/${obj?.id ?? ''}`}>
                          <button className='btn btn-icon btn-bg-light btn-active-color-info btn-sm me-1'>
                            <i className='fa-solid fa-arrow-down-long'></i>
                          </button>
                        </a>
                      </div> : ''}
                      
                       {/* {obj.file} */} 
                    </td>

                    {/* <td>{obj?.receipt ?? ''}</td>   */}
                    <td>{obj?.comment ?? ''}</td>
                  </tr>
                )
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BillingHistory
