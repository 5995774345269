import {ParsedQs} from 'qs'
import {API_BASE_URL} from '../../Constant'
import {setupAxios} from '../pages/auth'

export function getAllUsers() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/users', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

export function addUsers(formValues: {
  FirstName: string
  LastName: string
  Phone: string
  Email: string
  Role: string
}) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/auth/register', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      firstName: formValues.FirstName,
      lastName: formValues.LastName,
      phone: formValues.Phone,
      email: formValues.Email,
      userRoles: [formValues.Role],
    }),
  }).then((res) => res.json())
}

export function deleteUsers(id: string | undefined) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/users/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

export function getUsersById(id: string) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/users/${id}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

export function updateUserById(
  formValues: {
    FirstName: string
    LastName: string
    Phone: string
    Email: string
    Role: string
    // Company: string
    // Roles: [{label: 'Select Roles', value: ''}],
    //Role: ''
  },
  id: string
) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/users/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      firstName: formValues.FirstName,
      lastName: formValues.LastName,
      phone: formValues.Phone,
      email: formValues.Email,
      // company: formValues.Company,
      userRoles: [formValues.Role],
    }),
  }).then((res) => res.json())
}

// roles by permission  API

export function getRolesbyId(id: string) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/roles/${id}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

export function forgotPassword(email: string) {
  return fetch(API_BASE_URL + '/users/forgot-password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email: email,
    }),
  }).then((res) => res.json())
}

export function resetPassword(password: string, params: ParsedQs) {
  return fetch(
    API_BASE_URL +
      `/users/reset-password/?t=${params.t}&uuid=${params.uuid}&linkid=${params['?linkid']}`,
    {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        password: password,
      }),
    }
  ).then((res) => res.json())
}

export function updateUserEmailpassById(
  values: {
    password: string
    changepassword: string
  },
  id: string|null
) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/auth/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      password: values.password,
      changepassword: values.changepassword,
    }),
  }).then((res) => res.json())
}

export function setNotification(id:string, notification:boolean) {
  const {token} = setupAxios()
  return fetch(
    API_BASE_URL +
      `/users/email/${id}`,
    {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,},
      body: JSON.stringify({
        emailTogal:notification
      }),
    }
  ).then((res) => res.json())
}
