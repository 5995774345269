/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {Logout, AuthPage, useAuth} from '../pages/auth'
import {App} from '../App'
import {useEffect} from 'preact/hooks'

import {SupportRoutes} from './SupportRoutes'
import {AccountRoutes} from './AccountRoutes'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env

const AppRoutes: FC = () => {
  const {currentUser} = useAuth()
  //console.log(currentUser, currentUser?.userRoles[0]?.name)
  return (
    // <BrowserRouter basename={PUBLIC_URL}>
    //   <Routes>
    //     <Route element={<App />}>
    //       <Route path='error/*' element={<ErrorsPage />} />
    //       <Route path='logout' element={<Logout />} />
    //       {currentUser ? (
    //         <>
    //           <Route path='/*' element={<PrivateRoutes />} />
    //           <Route index element={<Navigate to='/dashboard' />} />
    //         </>
    //       ) : (
    //         <>
    //           <Route path='auth/*' element={<AuthPage />} />
    //           <Route path='*' element={<Navigate to='/auth' />} />
    //         </>
    //       )}
    //     </Route>
    //   </Routes>
    // </BrowserRouter>
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />
          {currentUser ? (
            <>
              {currentUser?.userRoles[0]?.roles === 'admin' ? (
                <Route path='/*' element={<PrivateRoutes />} />
              ) : currentUser?.userRoles[0]?.roles === 'support' ? (
                <Route path='/*' element={<SupportRoutes />} />
              ) : currentUser?.userRoles[0]?.roles === 'account' ? (
                <Route path='/*' element={<AccountRoutes />} />
              ) : (
                ''
              )}
              <Route
                index
                element={
                  <Navigate
                    to={`${
                      currentUser?.userRoles[0]?.roles === 'admin'
                        ? '/admin/dashboard'
                        : currentUser?.userRoles[0]?.roles === 'support'
                        ? '/support/dashboard'
                        : currentUser?.userRoles[0]?.roles === 'account'
                        ? '/account/dashboard'
                        : ''
                    }`}
                  />
                }
              />
            </>
          ) : (
            <>
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<Navigate to='/auth' />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export {AppRoutes}
