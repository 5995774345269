import axios from 'axios'
import {API_BASE_URL} from '../../../../Constant'
import {setupAxios} from './AuthHelpers'
import {AuthModel, UserModel} from './_models'

const API_URL = API_BASE_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/auth/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const REFRESHTOKEN_URL = `${API_URL}/refresh-token`

// Server should return AuthModel
export async function login(email: string, password: string) {
  const res = await fetch(LOGIN_URL , {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      //email: email + '@skeletos.in',
      email: email,
      password: password,
    }),
  })
  return await res.json()
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

export async function refreshToken() {
  const {token, refreshToken} = setupAxios()
  const res = await fetch(API_BASE_URL + '/refreshToken', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      refreshToken: refreshToken,
    }),
  })
  return await res.json()
}
