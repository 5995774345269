import {API_BASE_URL} from '../../Constant'
import {setupAxios} from '../pages/auth'

export function getAllTransactions() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/transactions', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}
// export function getAllBankRecords(id: string) {
//   const {token} = setupAxios()
//   return fetch(API_BASE_URL + `/BankDetails/${id}`, {
//     method: 'GET',
//     headers: {
//       'Content-type': 'application/json',
//       Authorization: `Bearer ${token}`,
//     },
//   }).then((res) => res.json())
// }

export function getAllBankRecords() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/BankDetails', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}
export function addTransactions(formData: any) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/transactions', {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

export function addReceiptTransactions(formData: any,id:string) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/transactions/files/${id}`, {
    method: 'PUT',
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

//download billing api
export function getAllInvoice(id: string) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/transactions/files/${id}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}
