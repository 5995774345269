import {API_BASE_URL} from '../../Constant'
import {setupAxios} from '../pages/auth'

export function getAllVendors() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/vendors', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

//add vendors

export function addVendors(formValues: {
  MajorSP: string
  Name: string
  UserId: string
  Password: string
  Phone: string
  ClientName: string
  City: string
  Location: string
  Address: string
  Email: string
  Startdate: string
  Renewaldate: string
  Duedate: string
  Amount: string
  PaymentMode: string
  BillingCycle: string
  BranchName: string
  AccountType: string
  IFSCcode: string
  AccountNumber: string
  PaymentLink: string
}) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/vendors', {
    method: 'POST',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      majorSP: formValues.MajorSP == '' ? null : formValues.MajorSP,
      name: formValues.Name,
      userId: formValues.UserId == '' ? null : formValues.UserId,
      password: formValues.Password == '' ? null : formValues.Password,
      phone: formValues.Phone,
      client: formValues.ClientName,
      city: formValues.City,
      location: formValues.Location,
      address: formValues.Address == '' ? null : formValues.Address,
      email: formValues.Email == '' ? null : formValues.Email,
      startDate: formValues.Startdate == '' ? null : formValues.Startdate,
      renewalDate: formValues.Renewaldate,
      dueDate: formValues.Duedate,
      amount: formValues.Amount,
      paymentMode: formValues.PaymentMode,
      paymentLink: formValues.PaymentLink == '' ? null : formValues.PaymentLink,
      bankDetail:
        formValues.PaymentMode == 'bank'
          ? {
              branch: formValues.PaymentMode == 'bank' ? formValues.BranchName : null,
              accountType: formValues.PaymentMode == 'bank' ? formValues.AccountType : null,
              accountNo: formValues.PaymentMode == 'bank' ? formValues.AccountNumber : null,
              ifsc: formValues.PaymentMode == 'bank' ? formValues.IFSCcode : null,
            }
          : null,
      billingCycle: formValues.BillingCycle,
    }),
  }).then((res) => res.json())
}

//delete vendors

export function deleteVendors(id: string | undefined) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/vendors/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

//edit posts

export function getVendorsById(id: string) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/vendors/${id}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

export function updateVendorsById(
  formValues: {
    MajorSP: string
    Name: string
    UserId: string
    Password: string
    Phone: string
    City: string
    Location: string
    Address: string
    Email: string
    Startdate: string
    Renewaldate: string
    Duedate: string
    Amount: string
    PaymentMode: string
    PaymentLink: string
    BillingCycle: string
    AccountNumber: string
    AccountType: string
    BranchName: string
    IFSCcode: string
    ClientName: string
  },
  id: string
) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/vendors/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      majorSP: formValues.MajorSP == '' ? null : formValues.MajorSP,
      name: formValues.Name,
      client: formValues.ClientName,
      userId: formValues.UserId == '' ? null : formValues.UserId,
      password: formValues.Password == '' ? null : formValues.Password,
      phone: formValues.Phone,
      city: formValues.City,
      location: formValues.Location,
      address: formValues.Address == '' ? null : formValues.Address,
      email: formValues.Email == '' ? null : formValues.Email,
      startDate: formValues.Startdate == '' ? null : formValues.Startdate,
      renewalDate: formValues.Renewaldate,
      dueDate: formValues.Duedate,
      amount: formValues.Amount,
      paymentMode: formValues.PaymentMode,
      paymentLink: formValues.PaymentLink == '' ? null : formValues.PaymentLink,
      bankDetail:
        formValues.PaymentMode == 'bank'
          ? {
              branch: formValues.PaymentMode == 'bank' ? formValues.BranchName : null,
              accountType: formValues.PaymentMode == 'bank' ? formValues.AccountType : null,
              accountNo: formValues.PaymentMode == 'bank' ? formValues.AccountNumber : null,
              ifsc: formValues.PaymentMode == 'bank' ? formValues.IFSCcode : null,
            }
          : null,
      billingCycle: formValues.BillingCycle,
    }),
  }).then((res) => res.json())
}

// for client name drop down. API for client name

export function getAllClients() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/client', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

//for all bank details
/*export function getAllbankDetails() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/BankDetails', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}*/

export function getVendorsbankDetails(id: string) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/vendors/bank/${id}`, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}
