import {lazy, FC, Suspense, useContext} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {checkRole, WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Client from '../pages/Client/Client'
import ServicesProvider from '../pages/ServicesProvider/ServicesProvider'
import Notification from '../pages/Notification/Notification'
import BillingHistory from '../pages/BillingHistory/BillingHistory'
import NewUser from '../pages/NewUser/NewUser'
import Support from '../pages/Support/Support'
import roleContext from '../../context/roleContext'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  let roleState: any = useContext(roleContext)

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/admin/dashboard' />} />
        {/* <Route path='auth/*' element={<Navigate to='/support/dashboard' />} /> */}
        {/* Pages */}
        <Route path='admin/dashboard' element={<DashboardWrapper />} />
        {checkRole(['client'], roleState.permissions) ? (
          <Route
            path='Client'
            element={
              <SuspensedView>
                <Client />
              </SuspensedView>
            }
          />
        ) : (
          ''
        )}
        {checkRole(['service_provider'], roleState.permissions) ? (
          <Route
            path='ServicesProvider'
            element={
              <SuspensedView>
                <ServicesProvider />
              </SuspensedView>
            }
          />
        ) : (
          ''
        )}
        {checkRole(['notification'], roleState.permissions) ? (
          <Route
            path='Notification'
            element={
              <SuspensedView>
                <Notification />
              </SuspensedView>
            }
          />
        ) : (
          ''
        )}
        {checkRole(['billing_history'], roleState.permissions) ? (
          <Route
            path='BillingHistory'
            element={
              <SuspensedView>
                <BillingHistory />
              </SuspensedView>
            }
          />
        ) : (
          ''
        )}
        {checkRole(['user'], roleState.permissions) ? (
          <Route
            path='NewUser'
            element={
              <SuspensedView>
                <NewUser />
              </SuspensedView>
            }
          />
        ) : (
          ''
        )}
        {checkRole(['support'], roleState.permissions) ? (
          <Route
            path='Support'
            element={
              <SuspensedView>
                <Support />
              </SuspensedView>
            }
          />
        ) : (
          ''
        )}

        <Route
          path='builder'
          element={
            <SuspensedView>
              <BuilderPageWrapper />
            </SuspensedView>
          }
        />
        {/* <Route path='menu-test' element={<MenuTestPage />} /> */}
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* <Route
          path='Client'
          element={
            <SuspensedView>
              <Client />
            </SuspensedView>
          }
        />
        <Route
          path='ServicesProvider'
          element={
            <SuspensedView>
              <ServicesProvider />
            </SuspensedView>
          }
        />
        <Route
          path='Notification'
          element={
            <SuspensedView>
              <Notification />
            </SuspensedView>
          }
        />
        <Route
          path='BillingHistory'
          element={
            <SuspensedView>
              <BillingHistory />
            </SuspensedView>
          }
        />
        <Route
          path='NewUser'
          element={
            <SuspensedView>
              <NewUser />
            </SuspensedView>
          }
        />
        <Route
          path='Support'
          element={
            <SuspensedView>
              <Support />
            </SuspensedView>
          }
        /> */}
        {/* npm i reactjs-popup */}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
