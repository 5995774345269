import React, {useEffect, useState} from 'react'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.css'
import InputError from '../../../_metronic/layout/components/InputError'
import {
  addClients,
  deleteClients,
  getAllClient,
  getClientsById,
  updateClientById,
} from '../../API/ClientAPI'
import {Modal} from 'react-bootstrap'
import {DefaultDataTableOption} from '../../../data/DataTableData'
import {toast} from 'react-toastify'
import {changeTextCapital, Message} from '../../../_metronic/helpers/AssetHelpers'
import Swal from 'sweetalert2'
import {AlertProps} from '../../../data/AlertDefalt'
import {DELETE_MESSAGE, UPDATE_MESSAGE} from '../../../Constant'

// import Popup from 'reactjs-popup';
const $ = require('jquery')
const initialValues = {
  Name: '',
  //CustomerID: '',
  ContactNo: '',
  EmailID: '',
  Address: '',
  // ServiceProviderID: '',
}

function Client() {
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState<any>({})
  const [posts, setPosts] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [isSubmit, setIsSubmit] = useState(false)
  const [editId, seteditId] = useState<string | null>(null)
  const [client, setClient] = useState([])
  const [searchTerm, setSearchTerm] = useState('')

  const handleChange = (e: {target: {name: any; value: any}}) => {
    const {name, value} = e.target
    setFormValues({...formValues, [name]: value})
  }

  const handleCancel = () => {
    setFormValues(initialValues)
    setFormErrors({})
    seteditId(null)
    setOpenModal(false)
  }

  const deleteClient = (id: string | undefined) => {
    Swal.fire({...AlertProps, icon: 'warning', title: DELETE_MESSAGE}).then((result: any) => {
      if (result.isConfirmed) {
        deleteClients(id).then((res) => {
          if (res.statusCode == 200) {
            $('#table_id1').dataTable().fnDestroy()
            toast.success(res.message)
            getClient()
          } else {
            Message(res.message, 'error')
          }
        })
      }
    })
  }

  const getClientById = (id: string) => {
    getClientsById(id).then((res) => {
      if (res.statusCode == 200) {
        setFormValues({
          ...formValues,
          Name: res?.data?.name ?? '',
          ContactNo: res?.data?.phone ?? '',
          EmailID: res?.data?.email ?? '',
          Address: res?.data?.address ?? '',
        })
        setOpenModal(true)
        seteditId(id)
      } else {
        seteditId(null)
      }
    })
  }

  const handleSubmit = () => {
    let {cnt, errors} = validate(formValues)
    setFormErrors(errors)
    console.log(editId)
    if (cnt == 0) {
      if (editId == null) {
        addClients(formValues).then((res) => {
          if (res.statusCode == 200) {
            toast.success(res.message)
            $('#table_id1').dataTable().fnDestroy()
            getClient()
            setOpenModal(false)
          } else {
            Message(res.message, 'error')
          }
        })
      } else {
        Swal.fire({...AlertProps, icon: 'warning', title: UPDATE_MESSAGE}).then((result: any) => {
          if (result.isConfirmed) {
            updateClientById(formValues, editId).then((res) => {
              if (res.statusCode == 200) {
                getClient()
                toast.success(res.message)
                $('#table_id1').dataTable().fnDestroy()
                setOpenModal(false)
              } else {
                Message(res.message, 'error')
              }
            })
          }
        })
      }
    }
  }

  const getClient = () => {
    getAllClient().then((res) => {
      if (res.statusCode == 200) {
        setClient(res.data)
        $(document).ready(function () {
          const searchBox = $('#table_id1')
          const dataTable = $('#table_id1').DataTable({
            ...DefaultDataTableOption,
            order: [[0, 'asc']],
            destroy: true,
          })
          $(document).on('keydown', function (event: JQuery.Event) {
            if (event.key === 'Escape') {
              searchBox.val('')
            }
          })
          $(document).on('click', function () {
            searchBox.val('')
          })
          searchBox.on('search', function () {
            searchBox.val('')
          })
          searchBox.on('input', function () {
            const searchTerm = searchBox.val()
            dataTable.search(searchTerm).draw()
          })
        })
      } else {
        setClient([])
      }
    })
  }

  const addNew = () => {
    setFormValues(initialValues)
    setFormErrors({})
    seteditId(null)
    setOpenModal(true)
  }

  useEffect(() => {
    let mounted = true
    if (mounted) {
      getClient()
    }
    return () => {
      mounted = false
    }
  }, [])

  const validate = (values: {
    Name: string
    // CustomerID: string
    ContactNo?: string
    EmailID?: string
    Address?: string
    className?: string
    // ServiceProviderID?: string
  }) => {
    const errors: any = {}
    let cnt = 0
    // let alphaRegex = /^[a-zA-Z][a-zA-Z\\s]+$/
    let alphaRegex = /^([a-z]+(\s{1})?)+[a-z]*$/i
    let alphaRegex1 = /^([a-z0-9]+(\s{1})?)+[a-z0-9]*$/i

    if (!values.Name) {
      cnt = cnt + 1
      errors.Name = 'Name is Required !'
    }
    // else {
    //   if (!alphaRegex.test(values.Name)) {
    //     cnt = cnt + 1
    //     errors.Name = 'Enter only alphabates !'
    //   }
    else {
      if (!alphaRegex.test(values.Name.trim())) {
        cnt = cnt + 1
        errors.Name = 'Only letters (a-z,A-Z) and single space are allowed'
      } else {
        if (values.Name.replaceAll(' ', '').length <= 1) {
          cnt = cnt + 1
          errors.Name = 'Name must be greater than 1 characters'
        }
        if (values.Name.replaceAll(' ', '').length > 50) {
          cnt = cnt + 1
          errors.Name = ' Name should not be greater than 50 characters'
        }
      }
    }

    let phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/
    if (!values.ContactNo) {
      cnt = cnt + 1
      errors.ContactNo = 'Contact No is Required !'
    } else {
      if (!phoneRegex.test(values.ContactNo)) {
        cnt = cnt + 1
        errors.ContactNo = 'Enter only 10 digit !'
      }
    }
    let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    if (!values.EmailID) {
      cnt = cnt + 1
      errors.EmailID = 'Email is Required !'
    } else {
      if (!emailRegex.test(values.EmailID)) {
        cnt = cnt + 1
        errors.EmailID = 'Enter correct Email ID !'
      }
    }
    if (!values.Address) {
      cnt = cnt + 1
      errors.Address = 'Address is Required !'
    }
    // if (!alphaRegex.test(values.Address)) {
    //   cnt = cnt + 1
    //   errors.Address = 'Enter only alphabates !'
    // }
    else {
      if (!alphaRegex1.test(values.Address.trim())) {
        cnt = cnt + 1
        errors.Address = 'Only letters (a-z,A-Z) and single space are allowed'
      } else {
        if (values.Address.replaceAll(' ', '').length <= 1) {
          cnt = cnt + 1
          errors.Address = 'Address must be greater than 1 characters'
        }
        if (values.Address.replaceAll(' ', '').length > 50) {
          cnt = cnt + 1
          errors.Address = ' Address should not be greater than 50 characters'
        }
      }
    }

    //console.log(errors)

    return {errors, cnt}
  }

  return (
    <div className='card'>
      <div className='card-header mt-5'>
        <span className='fs-1 '>Client</span>
        <div>
          <button type='button' className='btn btn-primary btn-sm ' onClick={() => addNew()}>
            Add New
          </button>
          <Modal
            size='lg'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            show={openModal}
            onHide={() =>  handleCancel() }
            animation={false}
          >
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title-vcenter'>Client Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <div className='mb-3 '>
                  <label htmlFor='Name' className='fs-6'>
                    Name
                  </label>
                  <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                  <input
                    className='form-control form-control-sm my-2 mx-0 col-12 col-sm-12'
                    type='text'
                    placeholder='Enter your name '
                    name='Name'
                    value={formValues.Name}
                    onChange={handleChange}
                  />
                  {<InputError Message={formErrors.Name} className='fs-6' />}
                </div>

                {/* <div className='mb-3 '>
                  <label htmlFor='Customer ID' className='form-label'>
                    Customer ID
                  </label>
                  <input
                    className='form-control form-control-sm'
                    type='number'
                    placeholder='Enter your Customer ID '
                    name='CustomerID'
                    value={formValues.CustomerID}
                    onChange={handleChange}
                  />
                  {<InputError Message={formErrors.CustomerID} />}
                </div> */}

                <div className='mb-3 '>
                  <label htmlFor='Contact No' className='fs-6'>
                    Contact No
                  </label>
                  <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                  <input
                    className='form-control form-control-sm my-2 mx-0 col-12 col-sm-12'
                    type='number'
                    placeholder='Enter your Contact No '
                    name='ContactNo'
                    value={formValues.ContactNo}
                    onChange={handleChange}
                  />
                  {<InputError Message={formErrors.ContactNo} className='fs-6' />}
                </div>

                <div className='mb-3 '>
                  <label htmlFor='Email ID' className='fs-6'>
                    Email ID
                  </label>
                  <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                  <input
                    className='form-control form-control-sm my-2 mx-0 col-12 col-sm-12'
                    type='email'
                    placeholder='Enter your Email Id'
                    name='EmailID'
                    value={formValues.EmailID}
                    onChange={handleChange}
                  />
                  {<InputError Message={formErrors.EmailID} className='fs-6' />}
                </div>

                <div className='mb-3 '>
                  <label htmlFor='Address' className='fs-6'>
                    Address
                  </label>
                  <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                  <input
                    className='form-control form-control-sm my-2 mx-0 col-12 col-sm-12'
                    type='text'
                    placeholder='Enter your Address'
                    name='Address'
                    value={formValues.Address}
                    onChange={handleChange}
                  />
                  {<InputError Message={formErrors.Address} className='fs-6' />}
                </div>

                {/* <div className='mb-3 '>
                  <label htmlFor='Service Provider ID' className=''>
                    Service Provider ID
                  </label>
                  <input
                    className='form-control form-control-sm'
                    type='text'
                    placeholder='Enter your Service Provider ID'
                    name='ServiceProviderID'
                    value={formValues.ServiceProviderID}
                    onChange={handleChange}
                  />
                  {<InputError Message={formErrors.ServiceProviderID} />}
                </div> */}
              </form>
            </Modal.Body>
            <Modal.Footer>
              <button className='btn btn-secondary btn-sm' onClick={() => handleCancel()}>
                Cancel
              </button>
              <button className='btn btn-primary btn-sm' onClick={(e) => handleSubmit()}>
                {/* Save */}
                {editId !== null ? 'Update' : 'Save'}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
      <div className='table-responsive'>
        <table
          className='table table-row-dashed table-row-gray-300 align-middle gs-0 table-bordered '
          id='table_id1'
        >
          {/* <table className='table' id='table_id1'> */}
          <thead className='text-centre'>
            <tr className='fw-bold text-muted'>
              <th
                className='pl-1'
                style={{
                  minWidth: '110px',
                  width: '100px',
                }}
              >
                {' '}
                {changeTextCapital('Customer Name')}
              </th>
              <th
                className='pl-1'
                style={{
                  minWidth: '110px',
                  width: '100px',
                }}
              >
                {changeTextCapital('Contact No')}
              </th>
              <th
                className='pl-1'
                style={{
                  minWidth: '150px',
                  width: '100px',
                }}
              >
                {changeTextCapital('Email Id')}
              </th>
              <th
                className='pl-1'
                style={{
                  minWidth: '110px',
                  width: '100px',
                }}
              >
                {changeTextCapital('Address')}
              </th>
              {/* <th scope='col'>Service Provider Id/Name</th> */}
              <th
                className='pl-1'
                style={{
                  minWidth: '110px',
                  width: '100px',
                }}
              >
                {changeTextCapital('Action')}
              </th>
            </tr>
          </thead>
          <tbody className='text-gray-800 fw-semibold text-left '>
            {client.map(
              (obj: {id: string; name: string; phone: string; email: string; address: string}) => {
                return (
                  <tr key={obj.id}>
                    <td>{obj.name}</td>
                    <td>{obj.phone}</td>
                    <td>{obj.email}</td>
                    <td>{obj.address}</td>

                    <td className=''>
                      <button
                        className='btn btn-icon btn-bg-light btn-sm me-1'
                        onClick={() => getClientById(obj.id)}
                      >
                        <i className='fa-solid fa-pen-to-square '></i>
                      </button>
                      <button
                        className='btn btn-icon btn-bg-light btn-sm me-1'
                        onClick={() => deleteClient(obj.id)}
                      >
                        <i className='fa-solid fa-trash'></i>
                      </button>
                    </td>
                  </tr>
                )
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Client
