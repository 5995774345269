import {DbCard1} from '../../../_metronic/partials/content/dashboard-cards/DbCard1'
import {DbCard2} from '../../../_metronic/partials/content/dashboard-cards/DbCard2'
// import AmountComponent from '../../../_metronic/partials/widgets/charts/amount'
// import Calender from '../../../_metronic/partials/widgets/charts/Calender'
// import PieChartComponent from '../../../_metronic/partials/widgets/charts/PieChart'
// import Grid from './Grid'
//import './Dashboard.css'

// const Dashboard = () => {
//   return (
//     <div className='dashboard'>
//       <div className='row'>
//         <div className='col-xs-12 col-sm-6'>
//           <Grid />
//         </div>
//         <div className='col-xs-12 col-sm-6'>
//           <Calender />
//           <br />
//           <span className='px-2'>
//             <i className='fa-solid fa-circle text-warning'></i> Due Date
//           </span>
//           <span className='px-2'>
//             <i className='fa-solid fa-circle text-danger'></i> Overdue Date
//           </span>
//           <span className='px-2'>
//             <i className='fa-solid fa-circle text-success'></i> Paid Date
//           </span>
//         </div>
//       </div>
//       <div className='row'>
//         <div className='col-xs-12 col-sm-6'>
//           <AmountComponent />
//         </div>
//         <div className='col-xs-12 col-sm-6'>
//           <PieChartComponent />
//         </div>
//       </div>
//     </div>
//   )
// }

// export default Dashboard

// import AmountComponent from '../../../_metronic/partials/widgets/charts/amount'
import Calender from '../../../_metronic/partials/widgets/charts/Calender'
// import PieChartComponent from '../../../_metronic/partials/widgets/charts/PieChart'
import Grid from './Grid'

const Dashboard = () => {
  return (
    <>
      {' '}
      <div className='main'>
        <DbCard1 />

        <div className='container px-3 mt-3'>
          <div className='row'>
            <DbCard2 />
          </div>
        </div>
        {/* <div className='row'>
          <div className='right-side col-4'>
            <Grid />
          </div>
          <div className='left-side col-8'>
            <Calender />
            <br />
            <span className='px-2'>
              <i className='fa-solid fa-circle text-warning '></i> Due Date
            </span>
            <span className='px-2'>
              <i className='fa-solid fa-circle text-danger'></i> Overdue Date
            </span>
            <span className='px-2'>
              <i className='fa-solid fa-circle text-success'></i> Paid Date
            </span>
          </div>
        </div> */}
        {/* <div className='row'>
          <div className='right-side col-6'>
            <AmountComponent />
          </div>
          <div className='left-side col-6 '>
            <PieChartComponent />

            <AmountComponent />
          </div>
        </div> */}
      </div>
    </>
  )
}

export default Dashboard
