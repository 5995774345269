import {useEffect, useState} from 'react'
import {PasswordMeterComponent} from '../../../_metronic/assets/ts/components'
import {updateUserEmailpassById} from '../../API/UsersAPI'
import { Modal } from 'react-bootstrap'
import { Message } from '../../../_metronic/helpers/AssetHelpers'
import { toast } from 'react-toastify'
import InputError from '../../../_metronic/layout/components/InputError'
import { useAuth } from '../auth'
const $ = require('jquery')


export function ChangePassword(props:{openModel1:boolean,setOpenModel1:Function,email_id:any,setEditId:Function,userType:string}) {
  const initialValues = {
    password: '',
    changepassword: '',
  }
  const {currentUser, logout} = useAuth()
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState<any>({})

  const handleChange = (e: {target: {name: any; value: any}}) => {
    const {name, value} = e.target
    setFormValues({...formValues, [name]: value})
  }

  const handleCancel = () => {
    setFormValues(initialValues)
    setFormErrors({})
    props.setEditId(null)
    props.setOpenModel1(false)   
    const searchInput = $('div.dataTables_filter input')
    searchInput.val('')
    searchInput.trigger('keyup')
  }

  const handleSubmit = (e: {
    preventDefault: (seteditId: Function, editId: string | null) => void
  }) => {
    let {cnt, errors} = validate(formValues)
    setFormErrors(errors)
    console.log(props.email_id)
    
    if (cnt == 0) {
        updateUserEmailpassById(formValues,props.email_id).then((res) => {
          if (res.statusCode == 200) {
            toast.success(res.message)
            props.setEditId(null)
         props.setOpenModel1(false)
            if(props.email_id===currentUser?.email){
              logout()
            }else{
    
              const searchInput = $('div.dataTables_filter input')
              searchInput.val('')
              searchInput.trigger('keyup')
            }
          } else {
            Message(res.message, 'error')
          }
        })
 
    }
  }

  function validate(values: {
   password: string
   changepassword: string
  }): any {
    const errors: any = {}
    let cnt = 0
    // console.log('in validate error')

    let alphaRegex = /^(?=\S*[a-z])(?=\S*[A-Z])(?=\S*\d)(?=\S*[^\w\s])\S{8,}$/

    if (!values.password) {
      cnt = cnt + 1
      errors.password = 'Password is required !'
    } else {
      if (!alphaRegex.test(values.password)) {
        cnt = cnt + 1
        errors.password = 'Wrong password format!'
      }
    }
    if (!values.changepassword) {
      cnt = cnt + 1
      errors.changepassword = 'Password confirmation is required!'
    } else {
      if (values.changepassword!==values.password) {
        cnt = cnt + 1
        errors.changepassword = 'Password and Confirm Password didn\'t match!'
      }
    }
    // if (!values.LastName) {
    //   cnt = cnt + 1
    //   errors.LastName = 'LastName id is required !'
    // } else {
    //   if (!alphaRegex.test(values.LastName)) {
    //     cnt = cnt + 1
    //     errors.LastName = 'Enter only alphabates !'
    //   }
    // }
    // let phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/
    // if (!values.Phone) {
    //   cnt = cnt + 1
    //   errors.Phone = 'Phone is required !'
    // } else {
    //   if (!phoneRegex.test(values.Phone)) {
    //     cnt = cnt + 1
    //     errors.Phone = 'Enter only 10 digit !'
    //   }
    // }

    // if (!values.Role) {
    //   cnt = cnt + 1
    //   errors.Role = 'role id is required !'
    // }
    // if (!values.UserId) {
    //   cnt = cnt + 1
    //   errors.UserId = 'User id is required !'
    // }
    // if (!values.OldId) {
    //   cnt = cnt + 1
    //   errors.OldId = 'Old Password is required !'
    // }
    // if (!values.NewId) {
    //   cnt = cnt + 1
    //   errors.NewId = 'New Password is required !'
    // }

    // let emailRegex =
    //   /^[a-z0-9]+(?!.*(?:\+{1,}|\-{1,}|\.{2,}))(?:[\.+\-]{0,1}[a-z0-9])*@(?:skeletos\.(?:in|io))$/
    // if (!values.Email) {
    //   cnt = cnt + 1
    //   errors.Email = 'Email is Required'
    // } else {
    //   if (!emailRegex.test(values.Email.trim())) {
    //     if (
    //       values.Email.charAt(values.Email.split('@')[0].length - 1) == '.' ||
    //       values.Email.charAt(0) == '.'
    //     ) {
    //       cnt = cnt + 1
    //       errors.Email =
    //         'Last or First character of the prefix of email must be letter (a-z) or number (0-9)'
    //     } else {
    //       cnt = cnt + 1
    //       errors.Email =
    //         'Only letters (a-z),numbers (0-9),and periods (.) are allowed in prefix of email and skeletos.in/skeletos.io are allowed in domain of email.'
    //     }
    //   } else if (!/[a-z]/gm.test(values.Email.split('@')[0])) {
    //     if (values.Email.replace('.', '')?.length >= 8) {
    //       cnt = cnt + 1
    //       errors.Email =
    //         'Email prefix of 8 or more characters must include at least one alphabetical character (a-z)'
    //     }
    //   } else {
    //     if (values.Email.replaceAll(' ', '').length < 6) {
    //       cnt = cnt + 1
    //       errors.Email = 'Email must be greater than 6 characters'
    //     }
    //     if (values.Email.replaceAll(' ', '').length > 75) {
    //       cnt = cnt + 1
    //       errors.Email = 'Email should not be greater than 75 characters'
    //     }
    //   }
    // }

    return {errors, cnt}
  }

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (

   
      <>

        <Modal
        size='sm'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        show={props.openModel1}
        onHide={() => handleCancel()}
        animation={false}
      >
                <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className='mb-10 text-center'>
          {/* begin::Title */}
          {/* end::Title */}
          <p className='text-white font-weight-bold h5'>
            Enter password and confirm password to reset
          </p>
          {/* begin::Link */}
          {/* end::Link */}
        </div>
        <div className='mb-10 fv-row' data-kt-password-meter='true'>
          <div className='mb-1'>
            <div className='position-relative mb-3'>
              <input
                type='password'
                placeholder='Password'
                onChange={handleChange}
            name='password'
            value={formValues.password}
                className='form-control form-control-solid h-auto py-5 px-6'
                 
                
              />
        <InputError Message={formErrors.password} />
            </div>
            {/* begin::Meter */}
            <div
              className='d-flex align-items-center mb-3'
              data-kt-password-meter-control='highlight'
            >
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
              <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className=''>Use 8 or more characters with a mix of letters, numbers & symbols.</div>
        </div>

        <div className='fv-row mb-5'>
          <input
            type='password'
            placeholder='Password confirmation'
            onChange={handleChange}
            name='changepassword'
            value={formValues.changepassword}
            className='form-control form-control-solid h-auto py-5 px-6'
          />
   <InputError Message={formErrors.changepassword }/>
        </div>
        </Modal.Body>
        <Modal.Footer>
          <button className='btn btn-secondary btn-sm' 
          onClick={() => handleCancel()}
          >
            Cancel
          </button>
          <button className='btn btn-primary btn-sm' 
          onClick={(e) => handleSubmit(e)}
          >
          Save
          </button>
        </Modal.Footer>
      </Modal>
      </>


  )
}
