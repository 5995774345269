import {API_BASE_URL} from '../../Constant'
import {setupAxios} from '../pages/auth'

export function getAllNotifications() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/notifications', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

export function updateNotification(id:string, notification:boolean) {
  const {token} = setupAxios()
  return fetch(
    API_BASE_URL +
      `/vendors/email/${id}`,
    {
      method: 'PUT',
      headers: {'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,},
      body: JSON.stringify({
        emailTogal:notification
      }),
    }
  ).then((res) => res.json())
}