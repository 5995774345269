import Swal, {SweetAlertIcon} from 'sweetalert2'

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export const Message = (Message: string, MessageType: SweetAlertIcon) => {
  Swal.fire({
    title: Message,
    icon: MessageType,
    heightAuto: false,
  })
}

export function calculateDateDifference(date1: Date, Date2: string) {
  var date2 = new Date(Date2)
  var isDateNull = false
  // To calculate the time difference of two dates
  var diffTime = date2.getTime() - date1.getTime()

  // To calculate the no. of days between two dates
  let diffDays = Math.ceil(diffTime / (1000 * 3600 * 24))
  if (date1 === null || Date2 === null) {
    isDateNull = true
  }
  return isDateNull ? 0 : diffDays
}

export function changeTextCamal(Text: string | undefined) {
  let words: any = ''

  words = Text?.split(' ')
  for (let i = 0; i < words?.length; i++) {
    words[i] = words[i][0]?.toUpperCase() + words[i]?.toLowerCase().substr(1)
  }

  return words.join(' ')
}

export function changeTextCapital(Text: string) {
  return Text.toUpperCase()
}

export const getDate = () => {
  const date = new Date()
  let day: any = date.getDate()
  let month: any = date.getMonth() + 1
  let year = date.getFullYear()

  if (day < 10) {
    day = `0${day}`
  }
  if (month < 10) {
    month = `0${month}`
  }
  const currentDate = `${year}-${month}-${day}`
  return {currentDate}
}

export const checkRole = (permissions: string[], permission: string[]) => {
  // Loop for array1
  for (let i = 0; i < permissions.length; i++) {
    // Loop for array2
    for (let j = 0; j < permission.length; j++) {
      // Compare the element of each and
      // every element from both of the
      // arrays
      if (permissions[i] === permission[j]) {
        // Return if common element found
        return true
      }
    }
  }

  // Return if no common element exist
  return false
}

export function isTokenExpired(token: any) {
  if (!token) return true // if no token is provided, assume it's expired
  const tokenParts = token.split('.')
  if (tokenParts.length !== 3) return true // invalid token format
  const decodedToken = JSON.parse(atob(tokenParts[1]))
  const currentTime = Math.floor(Date.now() / 1000) // convert milliseconds to seconds
  return decodedToken.exp < currentTime
}
