import React, {useState} from 'react'

const SearchBar = (props:{
fieldValue:string,
onChangeFunction:Function,
onClearFunction:Function,
isDisable:string,
name:string
}) => {
  const [searchTerm, setSearchTerm] = useState('')

  return (
    <div>
      {/* <input
        className='form-control'
        type='text'
        name={props.name}
        value={props.fieldValue}
        onChange={(event) => props.onChangeFunction(event)}
        placeholder='&#xF002; Search'
        style={{fontFamily:"Arial, FontAwesome"}}
        disabled={props.isDisable!=='false'}
      /> */}
      <div className=''>
            <div className='input-group'>
              <input
              className='form-control'
              type='text'
              name={props.name}
              value={props.fieldValue}
              onChange={(event) => props.onChangeFunction(event)}
              placeholder='&#xF002; Search'
              style={{fontFamily:"Arial, FontAwesome"}}
              disabled={props.isDisable!=='false'}
              />{' '}
              {
                props.isDisable=='current'?    <div className='input-group-postpend'>
                <span className='input-group-text' style={{cursor:'pointer'}} onClick={() => props.onClearFunction() }> <i className="fa-solid fa-xmark"></i></span>
              </div>:''
              }
          
            </div>

          </div>



    </div>
  )
}

export default SearchBar
