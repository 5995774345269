import 'datatables.net-dt/css/jquery.dataTables.css'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'jquery/dist/jquery.min.js'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { Message, changeTextCapital } from '../../../_metronic/helpers/AssetHelpers'
import InputError from '../../../_metronic/layout/components/InputError'
import { DefaultDataTableOption } from '../../../data/DataTableData'
import { DownSystem, UpSystem, getAllSupports, getServerlogsBySupportId } from '../../API/SupportAPI'
import { addReceiptTransactions, addTransactions } from '../../API/TransactionAPI'
const $ = require('jquery')

function Support() {
  //const initialValues = {Invoice: ''}
  const initialValues = {TransactionId: '', TransactionDate: '', Invoice: '', comment: '',Receipt: ''}
  const [openModal, setOpenModal] = useState(false)
  const [openModal1, setOpenModal1] = useState(false)
  const [support, setSupport] = useState([])
  const [records, setRecords] = useState([])
  const [formValues, setFormValues] = useState(initialValues)
  const [vendorDetail, setVendorDetail] = useState({id: '', amount: ''})
  const [transaction, setTransactions] = useState([])
  const [formErrors, setFormErrors] = useState<any>({})

  const handleCancel = () => {
    setFormValues(initialValues)
    setFormErrors({})
    setOpenModal(false)
    setOpenModal1(false)
  }

  const getsupport = () => {
    getAllSupports().then((res) => {
      if (res.statusCode == 200) {
        setSupport(res.data)
        $(document).ready(function () {
          $('#table_id1').DataTable({
            ...DefaultDataTableOption,
            //orderable: false,
            //order: [[1, 'asc']],
          })
        })
      } else {
        setSupport([])
      }
    })
  }

  const getRecords = (supportId: string) => {
    getServerlogsBySupportId(supportId).then((res) => {
      if (res.statusCode == 200) {
        setRecords(res.data)
        $(document).ready(function () {
          $('#table_id2').DataTable({
            ...DefaultDataTableOption,
            order: [[0, 'desc']],
          })
        })
        setOpenModal(true)
      } else {
        Message(res.message, 'error')
      }
    })
  }

  // const downSystem = (id: any) => {
  //   DownSystem(id).then((res) => {
  //     if (res.statusCode == 200) {
  //     }
  //   })
  // }

  const handleSubmit = (e: {preventDefault: () => void}) => {
    let {cnt, errors} = Validate(formValues)
    setFormErrors(errors)
    if (cnt == 0) {
      const formData = new FormData()
      formData.append('files', formValues.Invoice)
      formData.append('vendor', vendorDetail.id)
      formData.append('comment', formValues.comment)

      addTransactions(formData).then((res) => {
        if (res.statusCode == 200) {
          const formData = new FormData()
          formData.append('files', formValues.Receipt)
          addReceiptTransactions(formData,res.data.id).then((res2) => {
            if (res2.statusCode == 200) {
              toast.success(res2.message)
              // getTransaction()
              setOpenModal1(false)
            } else {
              Message(res2.message, 'error')
            }
          })
        } else {
          Message(res.message, 'error')
        }
      })
    }
  }

  const handleTabChange = (e: any, supports: any) => {
    console.log(e.target.checked, e.target.name)
    if (e.target.checked) {
      UpSystem(supports?.logId?.id).then((res) => {
        if (res.statusCode == 200) {
          getsupport()
        } else {
          Message(res.message, 'error')
        }
      })
    } else {
      DownSystem(supports.id).then((res) => {
        if (res.statusCode == 200) {
          Message(
            `Your ticket number is ${res.data.ticketName}`,'info'
          )
          getsupport()
        } else {
          Message(res.message, 'error')
        }
      })
    }
  }

  const openModalFunction = (vendors: any) => {
    setVendorDetail({
      ...vendorDetail,
      id: vendors?.id ?? '',
      //  amount: vendors?.amount ?? '',
    })
    setOpenModal1(true)
  }

  const handleChange = (e: React.SyntheticEvent, formValues: any) => {
    let target = e.target as HTMLInputElement
    if (target.name === 'Invoice' || target.name==='Receipt') {
      const file = target.files
      setFormValues({
        ...formValues,
        [target.name]: file == null ? '-' : file[0],
      })
    } else {
      setFormValues({
        ...formValues,
        [target.name]: target.value,
      })
    }
  }
  useEffect(() => {
    let mounted = true
    if (mounted) {
      getsupport()
      // getTransaction()
    }
    return () => {
      mounted = false
    }
  }, [])

  function Validate(values: {
    TransactionId: string
    TransactionDate: string
    Invoice: any
    Receipt: any
    comment: string
  }): any {
    const errors: any = {}
    let cnt = 0

    if (!values.Invoice) {
      cnt = cnt + 1
      errors.Invoice = 'invoice is required !'
    }
    let fileregex = /^.*\.(png|pdf|jpeg|txt)$/i
    if (!values.Invoice) {
      cnt = cnt + 1
      errors.Invoice = 'Invoice is Required !'
    } else {
      if (!fileregex.test(values.Invoice.name)) {
        cnt = cnt + 1
        errors.Invoice = 'Please select png, jpeg, txt, or PDF file'
      }
      
    }
    if (!values.Receipt) {
      cnt = cnt + 1
      errors.Receipt = 'Receipt is Required !'
    } else {
      if (!fileregex.test(values.Receipt.name)) {
        cnt = cnt + 1
        errors.Receipt = 'Please select png, jpeg, txt, or PDF file'
      }
    }

    return {errors, cnt}
  }

  return (
    <div>
      <div className='card'>
        <div className='card-header mt-5'>
          <span className='fs-1 '>Support</span>
          <div></div>
        </div>
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table' id='table_id1'>
              <thead className='fw-bold text-muted'>
                <tr>
                  <th
                    className='pl-1'
                    style={{
                      minWidth: '50px',
                      width: '50px',
                    }}
                  >
                    {changeTextCapital('Up Time')}
                  </th>
                  <th
                    className='pl-1'
                    style={{
                      minWidth: '100px',
                      width: '110px',
                    }}
                  >
                    {changeTextCapital('SP Name')}
                  </th>
                  <th
                    className='pl-1'
                    style={{
                      minWidth: '100px',
                      width: '110px',
                    }}
                  >
                    {changeTextCapital('Contact no')}
                  </th>
                  <th
                    className='pl-1'
                    style={{
                      minWidth: '100px',
                      width: '110px',
                    }}
                  >
                    {changeTextCapital('Location')}
                  </th>
                  <th
                    className='pl-1'
                    style={{
                      minWidth: '100px',
                      width: '110px',
                    }}
                  >
                    {changeTextCapital('Client Name')}{' '}
                  </th>
                  <th
                    className='pl-1'
                    style={{
                      minWidth: '90px',
                      width: '90px',
                    }}
                  >
                    {changeTextCapital('User ID')}
                  </th>
                  <th
                    className='pl-1'
                    style={{
                      minWidth: '90px',
                      width: '100px',
                    }}
                  >
                    {changeTextCapital('Invoice')}
                  </th>
                  {/* <th scope='col'>Invoice</th> */}

                  <th
                    className='pl-1'
                    style={{
                      minWidth: '100px',
                      width: '110px',
                    }}
                  >
                    {changeTextCapital('History')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* <select className='form-select form-select-sm' name='Role'>
                <option value='' hidden>
                  Select
                </option>

                <option value='1month'>1 Month</option>
                <option value='3months'>3 Months</option>
                <option value='6months'>6 Months</option>
                <option value='1year'>1 Year</option>
              </select> */}
                {support
                  ?.filter((item: any) => item.vendor != null)
                  ?.sort((x: any, y: any) => {
                    return x.isUptime === y.isUptime ? 0 : x.isUptime ? 1 : -1
                  })
                  .map(
                    (obj: {
                      client: {id: string; name: string; phone: string}
                      id: string
                      isUptime: boolean

                      vendor: {
                        id: string
                        majorSP: string
                        name: string
                        location: string
                        userId: string
                        phone: string
                      }
                    }) => {
                      return (
                        <tr>
                          <td>
                            <div className='form-check form-switch'>
                              <input
                                // className='form-check-input '
                                className={`form-check-input ${obj.isUptime ? 'bg-success' : 'bg-danger'}`}
                                type='checkbox'
                                name={obj.id}
                                // defaultChecked={obj.isUptime}
                                checked={obj.isUptime}
                                id='flexSwitchCheckDefault'
                                onChange={(e) => handleTabChange(e, obj)}
                              
                              />
                              <label
                                className='form-check-label'
                                htmlFor='flexSwitchCheckDefault'
                              ></label>
                            </div>
                          </td>
                          {/* <td>
                            <div className='form-check form-check-inline'>
                              <input
                                type='checkbox'
                                data-toggle='toggle'
                                data-onstyle='success'
                                data-offstyle='danger'
                                name={obj.id}
                                defaultChecked={obj.isUptime}
                                onChange={(e) => handleTabChange(e, obj)}
                              />
                              <label htmlFor='inlineCheckbox1' className='form-check-label'></label>
                            </div>
                          </td> */}
                          <td>{obj?.vendor?.name ?? '-'}</td>
                          <td
                            style={{
                              minWidth: '80px',
                              width: '100px',
                            }}
                          >
                            {obj?.vendor?.phone ?? '-'}
                          </td>
                          <td>{obj?.vendor?.location ?? '-'}</td>
                          <td
                            style={{
                              minWidth: '90px',
                              width: '100px',
                            }}
                          >
                            {obj?.client?.name ?? '-'}
                          </td>
                          <td>{obj?.vendor?.userId ?? '-'}</td>
                          <td
                            style={{
                              minWidth: '90px',
                              width: '100px',
                            }}
                          >
                            <div className='mb-3'>
                              <button
                                type='button'
                                // className='btn btn-primary btn-sm ms-2 '
                                className='btn btn-primary btn-sm'
                                onClick={() => {
                                  openModalFunction(obj.vendor)
                                  setOpenModal1(true)
                                }}
                              >
                                Invoice
                              </button>
                            </div>
                            {/* <div className='mb-3'>
                         <label className='form-label'>Invoice</label>
                          <input
                            type='file'
                            className='form-control'
                            id='Invoice'
                            name='Invoice'
                            onChange={(e) => handleChange(e, formValues)}
                          />
                        </div> */}
                          </td>

                          <td>
                            <button
                              className='btn btn-icon btn-bg-light  btn-sm me-5'
                              onClick={() => getRecords(obj.id)}
                            >
                              <i className='fa-solid fa-file-lines'></i>
                            </button>
                          </td>
                        </tr>
                      )
                    }
                  )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className=''>
        <Modal
          size='sm'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          show={openModal1}
          onHide={() => handleCancel() }
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>Transaction Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              {/* <div className='mb-3'>
                <label className='form-label'>Transaction ID</label>
                <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Enter transaction id'
                  name='TransactionId'
                  value={formValues.TransactionId}
                  onChange={(e) => handleChange(e, formValues)}
                />
                {<InputError Message={formErrors.TransactionId} />}
              </div>
              <div className='mb-3'>
                <label className='form-label'>Transaction Date</label>
                <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                <input
                  type='date'
                  className='form-control'
                  placeholder=' Enter transaction Date'
                  name='TransactionDate'
                  value={formValues.TransactionDate}
                  onChange={(e) => handleChange(e, formValues)}
                />
                {<InputError Message={formErrors.TransactionDate} />}
              </div> */}
              {/* <div className='mb-3'>
                         <label className='form-label'>Invoice</label>
                          <input
                            type='file'
                            className='form-control'
                            id='Invoice'
                            name='Invoice'
                            onChange={(e) => handleChange(e, formValues)}
                          />
                        </div>  */}
              <div className='mb-3'>
                <label className='form-label'>Invoice</label>
                <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                <input
                  type='file'
                  className='form-control'
                  id='Invoice'
                  name='Invoice'
                  onChange={(e) => handleChange(e, formValues)}
                />
                {<InputError Message={formErrors.Invoice} />}
              </div>
            <div className='mb-3'>
              <label className='form-label'>Receipt</label>
              <span className='text-danger ms-1 fs-4 fw-bold'>*</span>

              <input
                type='file'
                className='form-control'
                id='Receipt'
                name='Receipt'
                onChange={(e) => handleChange(e, formValues)}
              />
              {<InputError Message={formErrors.Receipt} />}
            </div>
              <div className='mb-3'>
                <label className='form-label'>Comment</label>
                {/* <span className='text-danger ms-1 fs-4 fw-bold'>*</span> */}
                <textarea
                  className='form-control'
                  id='exampleFormControlTextarea1'
                  // rows={50}
                  name='comment'
                  onChange={(e) => handleChange(e, formValues)}
                  style={{height: '100px'}}
                ></textarea>
                {<InputError Message={formErrors.comment} />}
              </div>

            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className='btn btn-secondary btn-sm' onClick={() => handleCancel()}>
              Cancel
            </button>
            <button className='btn btn-primary btn-sm' onClick={(e) => handleSubmit(e)}>
              Save
            </button>
          </Modal.Footer>
        </Modal>
      </div>

      <div className=''>
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          show={openModal}
          onHide={() => setOpenModal(false)}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>Down Time</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='table-responsive'>
              <table className='table' id='table_id2'>
                <thead>
                  <tr>
                  <th scope='col'>Ticket Number</th>
                    <th scope='col'>DownTime Date</th>
                    <th scope='col'>DownTime User</th>
                    <th scope='col'>UpTime Date</th>
                    <th scope='col'>UpTime User</th>
                    <th scope='col'>DownTime Record </th>
                  </tr>
                </thead>

                <tbody>
                  {records.map(
                    (obj: {
                      id: string
                      ticketName:string
                      downTime: string
                      downUser: {firstName: string; lastName: string}
                      upTime: string
                      upUser: {firstName: string; lastName: string}
                      totalTimes: string
                    }) => {
                      return (
                        <tr key={obj.id}>
                          <td>
                            {obj.ticketName !== null ? obj.ticketName : '-'}
                          </td>
                          <td>
                            {obj.downTime !== null ? new Date(obj.downTime).toLocaleString() : '-'}
                          </td>
                          <td>
                            {obj?.downUser?.firstName ?? '-'} {obj.downUser?.lastName ?? ''}
                          </td>
                          <td>
                            {obj.upTime !== null ? new Date(obj.upTime).toLocaleString() : '-'}
                          </td>
                          <td>
                            {obj?.upUser?.firstName ?? '-'} {obj.upUser?.lastName ?? ''}
                          </td>
                          <td>
                            {obj?.totalTimes !== null ? `${obj.totalTimes} ` : '-'}{' '}
                            {/* {obj.totalTimes} */}
                          </td>
                        </tr>
                      )
                    }
                  )}
                </tbody>
              </table>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default Support
