import {API_BASE_URL} from '../../Constant'
import {setupAxios} from '../pages/auth'

export function getAllRoles() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/roles', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

export function addRoles(FormValues: {Roles: {label: string; value: string}}) {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + `/roles`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      roles: FormValues.Roles.value,
    }),
  }).then((res) => res.json())
}

// export function addNewRole(formValues: {
//   FirstName: string
//   LasName: string
//   Phone: string
//   Company: string
//   Email: string
//   Role: string
// }) {
//   const {token} = setupAxios()
//   return fetch(API_BASE_URL + '/vendors', {
//     method: 'POST',
//     headers: {
//       'Content-type': 'application/json',
//       Authorization: `Bearer ${token}`,
//     },
//     body: JSON.stringify({
//       firstName: formValues.FirstName,
//       lastName: formValues.LasName,
//       phone: formValues.Phone,
//       company: formValues.Phone,
//       email: formValues.Email,
//       roles: formValues.Role,
//     }),
//   }).then((res) => res.json())
// }
