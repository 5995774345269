import React, {useEffect, useState} from 'react'
import 'jquery/dist/jquery.min.js'
import 'datatables.net-dt/js/dataTables.dataTables'
import 'datatables.net-dt/css/jquery.dataTables.css'
import InputError from '../../../_metronic/layout/components/InputError'
import {DefaultDataTableOption} from '../../../data/DataTableData'
import {
  addVendors,
  deleteVendors,
  getAllVendors,
  getVendorsbankDetails,
  getVendorsById,
  updateVendorsById,
} from '../../API/ServicesAPI'
import {Modal} from 'react-bootstrap'
import {getAllClient} from '../../API/ClientAPI'

import {DELETE_MESSAGE, UPDATE_MESSAGE} from '../../../Constant'
import {toast} from 'react-toastify'
import {changeTextCapital, Message} from '../../../_metronic/helpers/AssetHelpers'
import Swal from 'sweetalert2'
import {AlertProps} from '../../../data/AlertDefalt'
import {FALSE} from 'sass'
import {getAllBankRecords} from '../../API/TransactionAPI'

const $ = require('jquery')

const initialValues = {
  MajorSP: '',
  Name: '',
  UserId: '',
  Password: '',
  Phone: '',
  ClientName: '',
  City: '',
  Location: '',
  Address: '',
  Email: '',
  Startdate: '',
  Renewaldate: '',
  Duedate: '',
  Amount: '',
  PaymentMode: '',
  PaymentLink: '',
  BillingCycle: '',
  BranchName: '',
  AccountType: '',
  AccountNumber: '',
  IFSCcode: '',
}

function ServicesProvider() {
  const [formValues, setFormValues] = useState(initialValues)
  const [editId, seteditId] = useState<string | null>(null)
  const [formErrors, setFormErrors] = useState<any>({})
  const [isSubmit, setIsSubmit] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openModal1, setOpenModal1] = useState(false)
  const [client, setClients] = useState([{name: '', id: ''}])
  const [bankdetail, setBankDetail] = useState([
    {
      bankDetail: {
        accountNo: '1234590923',
        accountType: '',
        branch: 'Jm',
        deletedAt: null,
        id: 'afd648fe-515a-418f-aa45-a83e31d716fd',
        ifsc: 'ABCD0234567',
      },
    },
  ])
  /*const [bankRecords, setbankRecords] = useState([
    {
      accountNo: '',
      accountType: '',
      branch: '',
      deletedAt: null,
      id: '',
      ifsc: '',
    },
  ])*/
  // const [bankRecords, setbankRecords] = useState(false)

  const [isUpdating, setIsUpdating] = useState(true)

  const [vendors, setVendors] = useState<any>([
    {
      address: '',
      amount: '',
      bankDetail: {
        accountNo: '',
        accountType: '',
        branch: '',
        id: '',
        ifsc: '',
      },

      billingCycle: '',
      city: '',
      client: {
        address: '',
        email: '',
        id: '',
        name: '',
        phone: '',
      },
      dueDate: '',
      email: '',
      id: '',
      location: '',
      majorSP: '',
      name: '',
      paymentLink: null,
      paymentMode: '',
      phone: '',
      renewalDate: '',
      startDate: '',
    },
  ])

  const handleChange = (e: {target: {name: any; value: any}}) => {
    const {name, value} = e.target
    setFormValues({...formValues, [name]: value})
  }

  const handleSubmit = (e: {
    preventDefault: (seteditId: Function, editId: string | null) => void
  }) => {
    let {cnt, errors} = validate(formValues)
    setFormErrors(errors)
    console.log(editId)
    if (cnt == 0) {
      if (editId == null) {
        addVendors(formValues).then((res) => {
          if (res.statusCode == 200) {
            toast.success(res.message)
            $('#table_id1').dataTable().fnDestroy()
            getVendors()
            setOpenModal(false)
          } else {
            Message(res.message, 'error')
          }
        })
      } else {
        Swal.fire({...AlertProps, icon: 'warning', title: UPDATE_MESSAGE}).then((result: any) => {
          if (result.isConfirmed) {
            updateVendorsById(formValues, editId).then((res) => {
              if (res.statusCode == 200) {
                getVendors()
                toast.success(res.message)
                $('#table_id1').dataTable().fnDestroy()
                setOpenModal(false)
              } else {
                Message(res.message, 'error')
              }
            })
          }
        })
      }
    }
  }

  const handleCancel = () => {
    setFormValues(initialValues)
    setFormErrors({})
    seteditId(null)
    setOpenModal(false)   
  }

  const deletevendor = (id: string | undefined) => {
    Swal.fire({...AlertProps, icon: 'warning', title: DELETE_MESSAGE}).then((result: any) => {
      if (result.isConfirmed) {
        deleteVendors(id).then((res) => {
          if (res.statusCode == 200) {
            $('#table_id1').dataTable().fnDestroy()
            toast.success(res.message)
            getVendors()
          } else {
            Message(res.message, 'error')
          }
        })
      }
    })
  }

  const getclients = () => {
    getAllClient().then((res) => {
      if (res.statusCode == 200) {
        setClients(res.data)
      }
    })
  }

  const getBankDetail = (id: string) => {
    getVendorsbankDetails(id).then((res) => {
      if (res.statusCode == 200) {
        setBankDetail(res.data)
        setOpenModal1(true)
      }
    })
  }
  const getVendorById = (id: string) => {
    getVendorsById(id).then((res) => {
      if (res.statusCode == 200) {
        setFormValues({
          ...formValues,
          MajorSP: res?.data?.majorSP ?? '',
          Name: res?.data?.name ?? '',
          Phone: res?.data?.phone ?? '',
          City: res?.data?.city ?? '',
          Password: res?.data?.password ?? '',
          UserId: res?.data?.userId ?? '',
          Location: res?.data?.location ?? '',
          Address: res?.data?.address ?? '',
          Email: res?.data?.email ?? '',
          Startdate: res?.data?.startDate ?? '',
          Renewaldate: res?.data?.renewalDate ?? '',
          ClientName: res?.data?.client?.id ?? '',
          Duedate: res?.data?.dueDate ?? '',
          Amount: res?.data?.amount ?? '',
          PaymentMode: res?.data?.paymentMode.trim().toLocaleLowerCase() ?? '',
          PaymentLink: res?.data?.paymentLink ?? '',
          BranchName: res?.data?.bankDetail?.branch ?? '',
          BillingCycle: res?.data?.billingCycle ?? '',
          AccountNumber: res?.data?.bankDetail?.accountNo ?? '',
          AccountType: res?.data?.bankDetail?.accountType ?? '',
          IFSCcode: res?.data?.bankDetail?.ifsc ?? '',
        })
        setOpenModal(true)
        seteditId(id)
      } else {
        seteditId(null)
      }
    })
  }
  // const openModalFunction = (vendors: any) => {
  //   setVendorDetail({
  //     ...vendorDetail,
  //     id: vendors?.id ?? '',
  //     amount: vendors?.amount ?? '',
  //   })
  //   setOpenModal1(true)
  // }
  const getVendors = () => {
    getAllVendors().then((res) => {
      if (res.statusCode == 200) {
        setVendors(res.data)
        $(document).ready(function () {
          $('#table_id1').DataTable({
            ...DefaultDataTableOption,
            order: [[1, 'asc']],
          })
        })
      }
    })
  }
  const addNew = () => {
    setFormValues(initialValues)
    setFormErrors({})
    seteditId(null)
    setOpenModal(true)
  }
  useEffect(() => {
    let mounted = true
    if (mounted) {
      getVendors()
      getclients()
    }
    return () => {
      mounted = false
    }
  }, [])

  function validate(values: {
    MajorSP: string
    Name: string
    UserId: string
    Password: string
    Phone: string
    ClientName: string
    City: string
    Location: string
    Address?: string
    Email?: string
    BillingCycle: string
    Amount?: string
    Startdate: string
    Renewaldate?: string
    Duedate?: string
    PaymentLink: string
    PaymentMode: string
    BranchName: string
    AccountType: string
    AccountNumber: string
    IFSCcode: string
  }): any {
    const errors: any = {}
    let cnt = 0

    let alphaRegex = /^[A-Za-z ]+$/

    if (!values.Name) {
      cnt = cnt + 1
      errors.Name = 'Name  is Required !'
    } else {
      if (!alphaRegex.test(values.Name)) {
        cnt = cnt + 1
        errors.Name = 'Enter only alphabates !'
      }
    }

    if (values.ClientName == '') {
      cnt = cnt + 1
      errors.ClientName = 'Client Name  is Required !'
    }

    let phoneRegex = /^(\+\d{1,3}[- ]?)?\d{10}$/
    if (!values.Phone) {
      cnt = cnt + 1
      errors.Phone = 'Phone is required !'
    } else {
      if (!phoneRegex.test(values.Phone)) {
        cnt = cnt + 1
        errors.Phone = 'Enter only 10 digit !'
      }
    }
    if (!values.City) {
      cnt = cnt + 1
      errors.City = 'City is Required !'
    } else {
      if (!alphaRegex.test(values.City)) {
        cnt = cnt + 1
        errors.City = 'Enter only alphabates !'
      }
    }
    if (!values.Location) {
      cnt = cnt + 1
      errors.Location = 'Locaton is Required !'
    }

    // if (!values.Address) {
    //   cnt = cnt + 1
    //   errors.Address = 'Address is Required !'
    // }
    let emailRegex = /^([a-z0-9_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/g

    // if (!values.Email) {
    //   cnt = cnt + 1
    //   errors.Email = 'Email id is Required !'
    // } else {
    //   if (!emailRegex.test(values.Email)) {
    //     cnt = cnt + 1
    //     errors.Email = 'Enter correct Email ID !'
    //   }
    // }

    if (!values.BillingCycle) {
      cnt = cnt + 1
      errors.BillingCycle = 'Billing Cycle is Required !'
    } else if (Number(values.BillingCycle) < 0) {
      cnt = cnt + 1
      errors.BillingCycle = 'Billing Cycle should be grater than or equal to 0 !'
    }
    if (!values.Amount) {
      cnt = cnt + 1
      errors.Amount = 'Amount is Required !'
    } else if (Number(values.Amount) < 0) {
      cnt = cnt + 1
      errors.Amount = 'Amount should be grater than or equal to 0'
    }
    // if (!values.Startdate) {
    //   cnt = cnt + 1
    //   errors.Startdate = 'Start date is Required !'
    // }
    if (!values.Renewaldate) {
      cnt = cnt + 1
      errors.Renewaldate = ' Renewal date is Required !'
    }
    if (!values.Duedate) {
      cnt = cnt + 1
      errors.DueDate = 'Due date is Required !'
    }
    // if (!values.PaymentLink) {
    //   cnt = cnt + 1
    //   errors.PaymentLink = 'Payment link is required !'
    // }
    if (!values.PaymentMode) {
      cnt = cnt + 1
      errors.PaymentMode = 'Payment mode  is Required !'
    } else {
      if (values.PaymentMode === 'bank') {
        if (!values.BranchName) {
          cnt = cnt + 1
          errors.BranchName = 'Payment mode  is required !'
        }
        let accountRegex = /^\d{9,18}$/
        if (!values.AccountNumber) {
          cnt = cnt + 1
          errors.AccountNumber = 'Account number is required !'
        } else {
          if (!accountRegex.test(values.AccountNumber)) {
            cnt = cnt + 1
            errors.AccountNumber = 'Enter correct account number!'
          }
        }

        // if (!values.AccountType) {
        //   cnt = cnt + 1
        //   errors.AccountType = 'account type is required !'
        // }

        let ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/
        if (!values.IFSCcode) {
          cnt = cnt + 1
          errors.IFSCcode = 'IFSC code is Required !'
        } else {
          if (!ifscRegex.test(values.IFSCcode)) {
            cnt = cnt + 1
            errors.IFSCcode = 'Enter correct IFSC code!'
          }
        }
      }
    }

    return {errors, cnt}
  }

  return (
    <div>
      <div className='card '>
        <div className='card-header mt-5'>
          <span className='fs-1 '>Services Providers</span>
          <div>
            <button type='button' className='btn btn-primary btn-sm ' onClick={() => addNew()}>
              Add New
            </button>
            {/* popup */}
            <Modal
              size='lg'
              aria-labelledby='contained-modal-title-vcenter'
              centered
              show={openModal}
              onHide={() => handleCancel()}
              animation={false}
            >
              <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                  Service Provider Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form className=''>
                  <div className='row'>
                    <div className='col-6  '>
                      <label htmlFor='MajorSP' className='form-label'>
                        {' '}
                        Major SP
                      </label>
                      <input
                        className='form-control form-control-sm '
                        type='text'
                        placeholder='Enter your MajorSp'
                        name='MajorSP'
                        value={formValues.MajorSP}
                        onChange={handleChange}
                      />
                      {/* {<InputError Message={formErrors.MajorSP} />} */}
                    </div>

                    <div className='col-6  '>
                      <label htmlFor='Name' className='form-label'>
                        {' '}
                        Name
                      </label>
                      <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                      <input
                        className='form-control form-control-sm'
                        type='text'
                        placeholder='Enter your Name'
                        name='Name'
                        value={formValues.Name}
                        onChange={handleChange}
                      />
                      {<InputError Message={formErrors.Name} />}
                    </div>
                    <div className='col-6  '>
                      <label htmlFor='UserId' className='form-label'>
                        {' '}
                        User Id
                      </label>
                      {/* <span className='text-danger ms-1 fs-4 fw-bold'>*</span> */}
                      <input
                        className='form-control form-control-sm'
                        type='text'
                        placeholder='Enter your User Id'
                        name='UserId'
                        value={formValues.UserId}
                        //  disabled={editId !== null}
                        onChange={handleChange}
                      />

                      {/* {<InputError Message={formErrors.UserId} />} */}
                    </div>

                    <div className='col-6'>
                      <label htmlFor='Password' className='form-label'>
                        {' '}
                        Password
                      </label>
                      {/* <span className='text-danger ms-1 fs-4 fw-bold'>*</span> */}
                      <input
                        className='form-control form-control-sm'
                        type='text'
                        placeholder='Enter your Password'
                        name='Password'
                        value={formValues.Password}
                        //    disabled={editId !== null}
                        onChange={handleChange}
                      />
                      {/* {<InputError Message={formErrors.Password} />} */}
                    </div>
                    <div className='col-6  '>
                      <label htmlFor='Phone' className=' form-label'>
                        Phone
                      </label>
                      <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                      <input
                        className='form-control form-control-sm no-spinners'
                        type='number'
                        step='any'
                        placeholder='Enter your Contact No '
                        name='Phone'
                        value={formValues.Phone}
                        onChange={handleChange}
                      />
                      {<InputError Message={formErrors.Phone} />}
                    </div>
                    <div className='col-6  '>
                      <label htmlFor='client Name' className=' form-label'>
                        Client Name
                      </label>
                      <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                      <select
                        className='form-select form-select-sm '
                        placeholder='Select Client name '
                        name='ClientName'
                        value={formValues.ClientName}
                        //  disabled={editId !== null}
                        onChange={handleChange}
                      >
                        {<InputError Message={formErrors.ClientName} />}
                        <option value='' hidden>
                          Select Clients
                        </option>
                        {client.map((opts, i) => (
                          <option value={opts.id}>{opts.name}</option>
                        ))}
                      </select>
                      {<InputError Message={formErrors.ClientName} />}
                    </div>
                    <div className='col-6  '>
                      <label htmlFor='City' className='form-label'>
                        City
                      </label>
                      <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                      <input
                        className='form-control form-control-sm'
                        type='text '
                        placeholder='Enter your City'
                        name='City'
                        value={formValues.City}
                        onChange={handleChange}
                      />
                      {<InputError Message={formErrors.City} />}
                    </div>
                    <div className='col-6  '>
                      <label htmlFor='Location' className='form-label'>
                        Location
                      </label>
                      <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                      <input
                        className='form-control form-control-sm'
                        type='text '
                        placeholder='Enter your Location'
                        name='Location'
                        value={formValues.Location}
                        onChange={handleChange}
                      />
                      {<InputError Message={formErrors.Location} />}
                    </div>
                    <div className='col-6  '>
                      <label htmlFor=' Mailling Address' className='form-label'>
                        Address
                      </label>
                      {/* <span className='text-danger ms-1 fs-4 fw-bold'>*</span> */}
                      <input
                        className='form-control form-control-sm'
                        type='text'
                        placeholder='Enter your  Address'
                        name='Address'
                        value={formValues.Address}
                        onChange={handleChange}
                      />
                      {/* {<InputError Message={formErrors.Address} />} */}
                    </div>

                    <div className='col-6  '>
                      <label htmlFor='Email' className=' form-label'>
                        Email
                      </label>
                      {/* <span className='text-danger ms-1 fs-4 fw-bold'>*</span> */}
                      <input
                        className='form-control form-control-sm'
                        type='email'
                        placeholder='Enter your Email Id'
                        name='Email'
                        value={formValues.Email}
                        onChange={handleChange}
                      />
                      {/* {<InputError Message={formErrors.Email} />} */}
                    </div>

                    <div className='col-6  '>
                      <label htmlFor='Amount' className=' form-label'>
                        Amount
                      </label>
                      <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                      <input
                        className='form-control form-control-sm'
                        type='number'
                        placeholder='Enter your Amount'
                        name='Amount'
                        value={formValues.Amount}
                        onChange={handleChange}
                      />
                      {<InputError Message={formErrors.Amount} />}
                    </div>
                    <div className='col-6  '>
                      <label htmlFor='Billing cycle ' className='form-label'>
                        Billing Cycle (in months)
                      </label>
                      <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                      <input
                        className='form-control form-control-sm'
                        type='number'
                        placeholder='Enter your Billing Cycle'
                        name='BillingCycle'
                        value={formValues.BillingCycle}
                        onChange={handleChange}
                      />
                      {<InputError Message={formErrors.BillingCycle} />}
                    </div>
                    <div className='col-6  '>
                      <label htmlFor='Start Date' className='form-label'>
                        Start Date
                      </label>
                      {/* <span className='text-danger ms-1 fs-4 fw-bold'>*</span> */}
                      <input
                        className='form-control form-control-sm'
                        type='date'
                        placeholder='Enter Start date'
                        name='Startdate'
                        value={formValues.Startdate}
                        onChange={handleChange}
                      />
                      {/* {<InputError Message={formErrors.Startdate} />} */}
                    </div>
                    <div className='col-6  '>
                      <label htmlFor='Renewal Date ' className='form-label'>
                        Renewal Date
                      </label>
                      <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                      <input
                        className='form-control form-control-sm'
                        type='date'
                        placeholder='Enter Renewal date'
                        name='Renewaldate'
                        value={formValues.Renewaldate}
                        onChange={handleChange}
                      />
                      {<InputError Message={formErrors.Renewaldate} />}
                    </div>
                    <div className='col-6  '>
                      <label className='form-label'>Due Date</label>
                      <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                      <input
                        className='form-control form-control-sm'
                        type='date'
                        placeholder='Enter Due date'
                        name='Duedate'
                        value={formValues.Duedate}
                        onChange={handleChange}
                      />
                      {<InputError Message={formErrors.DueDate} />}
                    </div>
                    <div className='col-6  '>
                      <label className='form-label'>Payment Link</label>
                      <input
                        className='form-control form-control-sm'
                        type='text'
                        placeholder='Enter Payment Link'
                        name='PaymentLink'
                        value={formValues.PaymentLink}
                        onChange={handleChange}
                      />
                      {/* {<InputError Message={formErrors.PaymentLink} />} */}
                    </div>
                    <div className='col-6 '>
                      <label htmlFor='Start Date' className='form-label'>
                        Payment Mode
                      </label>
                      <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                      <select
                        className='form-select form-select-sm'
                        aria-label='Default select example'
                        name='PaymentMode'
                        value={formValues.PaymentMode}
                        onChange={handleChange}
                      >
                        <option selected>Payment Mode</option>
                        <option value='phonepay'>Phone Pay</option>
                        <option value='bank'>Bank </option>
                        <option value='gpay'>Gpay</option>
                        <option value='other mode'>Other Mode</option>
                      </select>
                      {<InputError Message={formErrors.PaymentMode} />}
                    </div>
                    {formValues.PaymentMode == 'bank' ? (
                      <>
                        <div className='col-6'>
                          <label className='form-label'>Bank Name</label>
                          <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                          <input
                            className='form-control form-control-sm'
                            type='text'
                            placeholder='Enter bank name'
                            name='BranchName'
                            value={formValues.BranchName}
                            onChange={handleChange}
                          />
                          {<InputError Message={formErrors.BranchName} />}
                        </div>
                        <div className='col-6  '>
                          <label className='form-label'>Account type</label>
                          <select
                            className='form-select form-select-sm'
                            aria-label='Default select example'
                            name='AccountType'
                            value={formValues.AccountType}
                            onChange={handleChange}
                          >
                            <option selected>Account type</option>
                            <option value='current'>Current</option>
                            <option value='saving'>Savings</option>
                          </select>
                          {/* {<InputError Message={formErrors.AccountType} />} */}
                        </div>
                        <div className='col-6 '>
                          <label className='form-label'>Account Number</label>
                          <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                          <input
                            className='form-control form-control-sm'
                            type='number'
                            placeholder='Enter account number'
                            name='AccountNumber'
                            value={formValues.AccountNumber}
                            onChange={handleChange}
                          />
                          {<InputError Message={formErrors.AccountNumber} />}
                        </div>
                        <div className='col-6 '>
                          <label className='form-label'>IFSC Code</label>
                          <span className='text-danger ms-1 fs-4 fw-bold'>*</span>
                          <input
                            className='form-control form-control-sm'
                            type='text'
                            placeholder='ABCD0123456'
                            name='IFSCcode'
                            value={formValues.IFSCcode}
                            onChange={handleChange}
                          />
                          {<InputError Message={formErrors.IFSCcode} />}
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <button className='btn btn-secondary btn-sm' onClick={() => handleCancel()}>
                  Cancel
                </button>
                <button className='btn btn-primary btn-sm' onClick={(e) => handleSubmit(e)}>
                  {/* Save */}
                  {editId !== null ? 'Update' : 'Save'}
                </button>
              </Modal.Footer>
            </Modal>

            {/* popup ends */}
          </div>
        </div>
        <div className='table-responsive'>
          <table
            className='table table-row-dashed table-row-gray-300 align-middle gs-0 table-bordered '
            id='table_id1'
          >
            {/* <table className='table ' id='table_id1'> */}
            <thead className='text-centre'>
              <tr className='fw-bold text-muted'>
                <th
                  className='pl-1'
                  style={{
                    minWidth: '60px',
                    width: '100px',
                  }}
                >
                  {changeTextCapital('Major SP')}
                </th>
                <th
                  style={{
                    minWidth: '110px',
                    width: '100px',
                  }}
                >
                  {changeTextCapital('Name')}
                </th>
                <th
                  style={{
                    minWidth: '110px',
                    width: '100px',
                  }}
                >
                  {changeTextCapital('User Id')}
                </th>
                <th
                  style={{
                    minWidth: '90px',
                    width: '100px',
                  }}
                >
                  {changeTextCapital('PassWord')}
                </th>
                <th
                  style={{
                    minWidth: '110px',
                    width: '100px',
                  }}
                >
                  {changeTextCapital('Phone')}
                </th>
                <th
                  style={{
                    minWidth: '70px',
                    width: '100px',
                  }}
                >
                  {changeTextCapital('City')}
                </th>
                <th
                  style={{
                    minWidth: '70px',
                    width: '100px',
                  }}
                >
                  {changeTextCapital('Location')}
                </th>
                <th
                  style={{
                    minWidth: '110px',
                    width: '100px',
                  }}
                >
                  {changeTextCapital('Address')}
                </th>
                <th
                  style={{
                    minWidth: '110px',
                    width: '100px',
                  }}
                >
                  {changeTextCapital('Email')}
                </th>
                <th
                  style={{
                    minWidth: '80px',
                    width: '100px',
                  }}
                >
                  {changeTextCapital('Billing Cycle')}(in month)
                </th>
                <th
                  style={{
                    minWidth: '110px',
                    width: '100px',
                  }}
                >
                  {changeTextCapital('Client Name')}
                </th>
                <th
                  style={{
                    minWidth: '60px',
                    width: '100px',
                  }}
                >
                  {changeTextCapital('Amount')}
                </th>
                <th
                  style={{
                    minWidth: '70px',
                    width: '100px',
                  }}
                >
                  {changeTextCapital('start Date')}
                </th>
                <th
                  style={{
                    minWidth: '70px',
                    width: '100px',
                  }}
                >
                  {changeTextCapital('renewal date')}
                </th>
                <th
                  style={{
                    minWidth: '70px',
                    width: '100px',
                  }}
                >
                  {changeTextCapital('Due date')}
                </th>
                <th
                  style={{
                    minWidth: '110px',
                    width: '100px',
                  }}
                >
                  {changeTextCapital('Payment Mode')}
                </th>
                <th
                  style={{
                    minWidth: '110px',
                    width: '100px',
                  }}
                >
                  {changeTextCapital('Action')}
                </th>
              </tr>
            </thead>
            <tbody>
              {vendors.map(
                (obj: {
                  id: string
                  paymentLink: string
                  majorSP: string
                  userId: string
                  name: string
                  password: string
                  phone: string
                  client: {name: string}
                  bankDetail: {
                    accountNo: string
                    accountType: string
                    branch: string
                    id: string
                    ifsc: string
                  }

                  city: string
                  location: string
                  address: string
                  email: string
                  billingCycle: string
                  amount: string
                  startDate: string
                  renewalDate: string
                  dueDate: string
                  paymentMode: string
                }) => {
                  return (
                    <tr key={obj.id}>
                      <td>{obj.majorSP}</td>
                      <td>{obj.name}</td>
                      <td>{obj.userId}</td>
                      <td>{obj.password}</td>
                      <td>{obj.phone}</td>
                      <td>{obj.city}</td>
                      <td>{obj.location}</td>
                      <td>{obj.address}</td>
                      <td>{obj.email}</td>
                      <td>{obj.billingCycle}</td>
                      <td>{obj?.client?.name ?? '-'}</td>
                      <td>{obj.amount}</td>
                      <td>{obj.startDate}</td>
                      <td>{obj.renewalDate}</td>
                      <td>{obj.dueDate}</td>
                      {/* <td></td> */}

                      {/* <td>
                        <a href={obj.paymentLink} target='_blank'>
                          {obj.paymentMode}
                        </a>
                      </td> */}
                      <td>
                        {obj.paymentMode.toLocaleLowerCase().trim() === 'bank' ? (
                          <button
                            className='btn btn-primary btn-sm'
                            type='button'
                            onClick={() => {
                              getBankDetail(obj?.bankDetail?.id??'')

                              setOpenModal1(true)
                            }}
                          >
                            bank
                          </button>
                        ) : (
                          // <a href={obj.paymentLink} target='_blank'>
                            obj.paymentMode
                          // </a>
                        )}
                      </td>
                      <td className=''>
                        <button
                          className='btn btn-icon btn-bg-light btn-sm me-1'
                          onClick={() => getVendorById(obj.id)}
                        >
                          <i className='fa-solid fa-pen-to-square '></i>
                        </button>
                        <button
                          className='btn btn-icon btn-bg-light btn-sm me-1'
                          onClick={() => deletevendor(obj.id)}
                        >
                          <i className='fa-solid fa-trash'></i>
                        </button>
                      </td>
                    </tr>
                  )
                }
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div className=''>
        <Modal
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          show={openModal1}
          onHide={() => setOpenModal1(false)}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>Bank Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              {bankdetail===null?<span className='text-center'>No Data Available</span>:
              <>
     <label className='col-sm-3 col-md-3 col-6 fw-semibold text-muted h5 mb-5'>
                Bank Name:
              </label>

              <div className='col-sm-3 col-md-3 col-6 mb-5'>
                <span className='fw-bold fs-6 text-gray-800'>
                  {bankdetail[0]?.bankDetail?.branch ?? '-'}
                </span>
              </div>
              <label className='col-sm-3 col-md-3 col-6 fw-semibold text-muted h5 mb-5'>
                Account Type:
              </label>

              <div className='col-sm-3 col-md-3 col-6 mb-5'>
                <span className='fw-bold fs-6 text-gray-800'>
                  {bankdetail[0]?.bankDetail?.accountType ?? '-'}
                </span>
              </div>
              <label className='col-sm-3 col-md-3 col-6 fw-semibold text-muted h5 mb-5'>
                Account No:
              </label>

              <div className='col-sm-3 col-md-3 col-6 mb-5'>
                <span className='fw-bold fs-6 text-gray-800'>
                  {bankdetail[0]?.bankDetail?.accountNo ?? '-'}
                </span>
              </div>
              <label className='col-sm-3 col-md-3 col-6 fw-semibold text-muted h5 mb-5'>
                IFSC Code:
              </label>

              <div className='col-sm-3 col-md-3 col-6 mb-5'>
                <span className='fw-bold fs-6 text-gray-800'>
                  {bankdetail[0]?.bankDetail?.ifsc ?? '-'}
                </span>
              </div>
              </>
              }
         
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default ServicesProvider
