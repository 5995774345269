import {API_BASE_URL} from '../../Constant'
import {setupAxios} from '../pages/auth'

export function getAllThisweek() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/dashboard/between', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

export function getAllNextweek() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/dashboard/nextweek', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

export function getAllPaid() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/dashboard/paid', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}
export function getTodayDue() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/dashboard/today', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}
export function getAllOverDue() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/dashboard/overdue', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}

 export function getCount() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/dashboard/count', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
} 


export function getDueOverDueVendor() {
  const {token} = setupAxios()
  return fetch(API_BASE_URL + '/dashboard/serviceProvider', {
    method: 'GET',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.json())
}
