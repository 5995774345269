/* eslint-disable jsx-a11y/anchor-is-valid */
import {useContext, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login, refreshToken} from '../core/_requests'
import {Message, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {forgotPassword, getRolesbyId} from '../../../API/UsersAPI'
import roleContext from '../../../../context/roleContext'
import Swal from 'sweetalert2'
import { CANCLE_BUTTON_COLOR, CANCLE_BUTTON_TEXT, CONFIRM_BUTTON_COLOR, CONFIRM_BUTTON_TEXT } from '../../../../Constant'
import { Logout } from '../Logout'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .matches(
      /^[a-z0-9]+(?!.*(?:\+{1,}|\-{1,}|\.{2,}))(?:[\.+\-]{0,1}[a-z0-9])*@(?:skeletos\.(?:in|io))$/,
      (obj) => {
        return obj.originalValue.charAt(obj.originalValue.split('@')[0].length - 1) === '.' ||
          obj.originalValue.charAt(0) === '.'
          ? 'Last or First character of the prefix of email must be letter (a-z) or number (0-9)'
          : 'Only letters (a-z),numbers (0-9),and periods (.) are allowed in prefix of email and skeletos.in/skeletos.io are allowed in domain of email.'
      }
    )
    .test(
      'emailvalid',
      'Email prefix of 8 or more characters must include at least one alphabetical character (a-z)',
      function (item: any) {
        return !/[a-z]/gm.test(item.split('@')[0])
          ? item.split('@')[0].replace('.', '')?.length >= 8
            ? false
            : true
          : true
      }
    )

    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

// function validateEmail(email: string): boolean {
//   //const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
//   let alphaRegex = /^[a-zA-Z]+@[a-z]*$/i
//   let desiredDomain = '@skeletos.in'

//   if (!alphaRegex.test(email)) {
//     return false
//   }

//   if (!email.endsWith(desiredDomain)) {
//     return false
//   }

//   return true
// }

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const [intervalId, setIntervalId] = useState<any>(null)
  const {saveAuth, setCurrentUser, logout} = useAuth()
  const navigate = useNavigate()
  let roleState: any = useContext(roleContext)
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    validateOnBlur: true,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      await login(values.email, values.password).then((res) => {
        setSubmitting(false)
        setLoading(false)
        if (res.statusCode == 200) {
          saveAuth(res.data)
          setCurrentUser(res.data)
          sessionStorage.setItem('isLoggedIn', 'true')
        const id = setInterval(() => {
            Swal.fire({
              icon: 'warning',
              title: 'Your session has expired. Please login to continue',
              heightAuto: false,
              showCancelButton:true,
              reverseButtons:false,
              cancelButtonText:CANCLE_BUTTON_TEXT,
              confirmButtonText:CONFIRM_BUTTON_TEXT,
              cancelButtonColor:CANCLE_BUTTON_COLOR,
              confirmButtonColor:CONFIRM_BUTTON_COLOR

            }).then((result: any) => {
              logout()
              if(result.isConfirmed){
                Logout()
              }else{
                refreshToken().then((res) => {
                  if(res.statusCode ==  200){
                    const storageItem: any = localStorage.getItem('kt-auth-react-v')
                    const user: any = JSON.parse(storageItem)
                    // Update a property of the object
                    user.accessToken = res.data.accessToken
                    user.refreshToken = res.data.refreshToken
                    // Set the updated object back into local storage
                    saveAuth(user)
                    setCurrentUser(user)
                  }else{
                    clearInterval(intervalId)
                    setIntervalId(null)

                  }
                })
              }
            })
          }, 14400000)
          setIntervalId(id)
          localStorage.setItem('intervalId', id.toString())
          setSubmitting(false)
          setLoading(false)
          getRolesbyId(res.data.userRoles[0].id).then((res) => {
            let permissions: any = []
            if (res.statusCode == 200) {
              res.data.permissions.map((obj: any) => {
                permissions.push(obj.name)
              })
              roleState.updateState(permissions)
              localStorage.setItem('permissions', JSON.stringify(permissions))
            }
          })
        } else if (res.statusCode == 406) {
          forgotPassword(values.email).then((res) => {
            setSubmitting(false)
            setLoading(false)
            if (res.statusCode == 200) {
              let Url = res.data.split('/auth')
              navigate(`/auth${Url[1]}`)
              setStatus(
                'Reset link sent successfully. Please check your email and Reset your password to login'
              )
            } else {
              setStatus(res.message)
            }
          })
          // navigate(`/auth/forget-password`)
          // Message(res.message, 'warning')
          // setStatus(res.message)
        } else {
          setStatus(res.message)
          saveAuth(undefined)
          setSubmitting(false)
          setLoading(false)
        }
      })
    },
  })

  return (
    // <form
    //   className='form w-100'
    //   onSubmit={formik.handleSubmit}
    //   noValidate
    //   id='kt_login_signin_form'
    // >
    //   {/* begin::Heading */}
    //   <div className='text-center mb-10'>
    //     <h1 className='text-dark mb-3'>Sign In </h1>
    //     {/* <div className='text-gray-400 fw-bold fs-4'>
    //       New Here?{' '}
    //       <Link to='/auth/registration' className='link-primary fw-bolder'>
    //         Create an Account
    //       </Link>
    //     </div> */}
    //   </div>
    //   {/* begin::Heading */}

    //   {formik.status ? (
    //     <div className='mb-lg-15 alert alert-danger'>
    //       <div className='alert-text font-weight-bold'>{formik.status}</div>
    //     </div>
    //   ) : (
    //     ''
    //   )}

    //   {/* begin::Form group */}
    //   {/*<div className='fv-row mb-10'>
    //     <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
    //     <input
    //       placeholder='Email'
    //       {...formik.getFieldProps('email')}
    //       className={clsx(
    //         'form-control form-control-lg form-control-solid'
    //         // {'is-invalid': formik.touched.email && formik.errors.email},
    //         // {
    //         //   'is-valid': formik.touched.email && !formik.errors.email,
    //         // }
    //       )}
    //       type='email'
    //       name='email'
    //       autoComplete='off'
    //       value={formik.values.email}
    //       onChange={formik.handleChange}
    //       onBlur={formik.handleBlur}
    //     />{' '}
    //     {formik.touched.email && formik.errors.email && (
    //       <div className='fv-plugins-message-container'>
    //         <div className='fv-help-block'>
    //           <span role='alert'>{formik.errors.email}</span>
    //         </div>
    //       </div>
    //     )}
    //   </div>
    //   {/* end::Form group */}

    //   <div className='form-group row mb-8'>
    //     <div className='input-group'>
    //       <input
    //         placeholder='Email'
    //         {...formik.getFieldProps('email')}
    //         className={clsx('form-control form-control-solid h-auto py-5 px-6')}
    //         type='email'
    //         name='email'
    //         value={formik.values.email}
    //         onChange={formik.handleChange}
    //         onBlur={formik.handleBlur}
    //         autoComplete='off'
    //       />{' '}
    //       <div className='input-group-postpend'>
    //         <span className='input-group-text h-auto py-5 px-6'> @skeletos.in</span>
    //       </div>
    //       {/* <div className='input-group-postpend'>
    //         <select className='form-select h-auto py-5 px-6'>
    //           <option value='skeletos.in'>@skeletos.in</option>
    //           <option value='skeletos.io'>@skeletos.io</option>
    //         </select>
    //       </div> */}
    //     </div>
    //     {formik.touched.email && formik.errors.email && (
    //       <div className='fv-plugins-message-container'>
    //         <div className='fv-help-block'>
    //           <span role='alert'>{formik.errors.email}</span>
    //         </div>
    //       </div>
    //     )}
    //   </div>

    //   {/* begin::Form group */}
    //   <div className='fv-row mb-10'>
    //     <div className='d-flex justify-content-between mt-n5'>
    //       <div className='d-flex flex-stack mb-2'>
    //         {/* begin::Label */}
    //         <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
    //         {/* end::Label */}
    //         {/* begin::Link */}
    //         <Link
    //           to='/auth/forgot-password'
    //           className='link-primary fs-6 fw-bolder'
    //           style={{marginLeft: '5px'}}
    //         >
    //           Forgot Password ?
    //         </Link>
    //         {/* end::Link */}
    //       </div>
    //     </div>
    //     <input
    //       type='password'
    //       autoComplete='off'
    //       {...formik.getFieldProps('password')}
    //       className={clsx(
    //         'form-control form-control-lg form-control-solid'
    //         // {
    //         //   'is-invalid': formik.touched.password && formik.errors.password,
    //         // },
    //         // {
    //         //   'is-valid': formik.touched.password && !formik.errors.password,
    //         // }
    //       )}
    //     />
    //     {formik.touched.password && formik.errors.password && (
    //       <div className='fv-plugins-message-container'>
    //         <div className='fv-help-block'>
    //           <span role='alert'>{formik.errors.password}</span>
    //         </div>
    //       </div>
    //     )}
    //   </div>
    //   {/* end::Form group */}

    //   {/* begin::Action */}
    //   <div className='text-center'>
    //     <button
    //       type='submit'
    //       id='kt_sign_in_submit'
    //       className='btn btn-lg btn-primary w-100 mb-5'
    //       disabled={formik.isSubmitting || !formik.isValid}
    //     >
    //       {!loading && <span className='indicator-label'>Continue</span>}
    //       {loading && (
    //         <span className='indicator-progress' style={{display: 'block'}}>
    //           Please wait...
    //           <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    //         </span>
    //       )}
    //     </button>

    //     {/* begin::Separator */}
    //     {/* <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div> */}
    //     {/* end::Separator */}

    //     {/* begin::Google link */}
    //     {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
    //       <img
    //         alt='Logo'
    //         src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
    //         className='h-20px me-3'
    //       />
    //       Continue with Google
    //     </a> */}
    //     {/* end::Google link */}

    //     {/* begin::Google link */}
    //     {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
    //       <img
    //         alt='Logo'
    //         src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
    //         className='h-20px me-3'
    //       />
    //       Continue with Facebook
    //     </a> */}
    //     {/* end::Google link */}

    //     {/* begin::Google link */}
    //     {/* <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
    //       <img
    //         alt='Logo'
    //         src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
    //         className='h-20px me-3'
    //       />
    //       Continue with Apple
    //     </a> */}
    //     {/* end::Google link */}
    //   </div>
    //   {/* end::Action */}
    // </form>
    <>
      <div
        className='login-form login-signin p-10 card'
        id='kt_login_signin_form'
        style={{
          background: 'rgba(55, 55, 58, 0.35)',
          boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
          backdropFilter: 'blur(0.6px)',
          WebkitBackdropFilter: 'blur(0.6px)',
        }}
      >
        <div className='text-center mb-10 mb-lg-20'>
          <h3 className='font-size-h1 text-white'>Sign In</h3>
          <p className='font-weight-bold h5 text-white'>Enter your username and password</p>
        </div>
        <form
          noValidate
          onSubmit={formik.handleSubmit}
          className='form fv-plugins-bootstrap fv-plugins-framework '
        >
          {formik.status ? (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          ) : (
            ''
          )}
          {/* begin::Form group */}
          <div className='form-group row mb-8'>
            <div className='input-group'>
              <input
                placeholder='Email'
                {...formik.getFieldProps('email')}
                className={clsx('form-control form-control-solid h-auto py-5 px-6')}
                type='email'
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                autoComplete='off'
              />{' '}
              {/* <div className='input-group-postpend'>
                <span className='input-group-text h-auto py-5 px-6'> @skeletos.in</span>
              </div> */}
            </div>
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <input
              type='password'
              autoComplete='off'
              placeholder='Password'
              {...formik.getFieldProps('password')}
              className={clsx('form-control form-control-solid h-auto py-5 px-6')}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
            {/* <div className='pt-3'>
              {' '}
              <span className='text-danger'> Note</span>{' '}
              <span className='text-muted'> First Time Login Password &gt; Password@123</span>
            </div> */}
          </div>
          {/* end::Form group */}
          {/* begin::Action */}
          <div className='form-group d-flex flex-wrap justify-content-between align-items-center'>
            {/* begin::Link */}
            <Link
              to='/auth/forgot-password'
              className='text-white text-hover-primary my-3 mr-2 h6'
              style={{marginLeft: '5px'}}
            >
              Forgot Password ?
            </Link>
            {/* end::Link */}

            <button
              type='submit'
              id='kt_sign_in_submit'
              className='btn btn-primary font-weight-bold px-9 py-4 my-3'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Sign In</span>}
              {loading && (
                <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
          {/* end::Action */}
        </form>
      </div>
    </>
  )
}
